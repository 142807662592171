<script>
import { ref } from 'vue'
import { BookOpenIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
// import { customerReservationFn, getCustomerInfoFn } from '@/services/api/restApiCalls'
import ItemsList from '@/components/posbill/ItemsList.vue'
import PayBillModal from '@/components/posbill/PayBillModal.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'

export default {
  components: {
    BookOpenIcon,
    ClipboardIcon,
    ItemsList,
    PayBillModal
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const name = ref('')
    const showPayBillModal = ref(false)

    return {
      name,
      router,
      restoStore,
      showPayBillModal
    }
  }
}
</script>
<template>
  <!-- <AppLayout> -->
  <div class="flex flex-col items-center justify-center mx-auto bg-gray-100">
    <div class="w-full max-w-2xl bg-white rounded-sm shadow">
      <div class="top-banner">
        <div class="overflow-hidden -mt-8">
          <img
            class="block h-20 w-auto mx-auto rounded-full"
            :src="restoStore.restoInfo.logo"
            :alt="restoStore.restoInfo.name"
          />
        </div>
      </div>
      <div class="mt-10 px-4 py-6">
        <div class="text-center mb-4">
          <span class="text-sm">Table 40 </span>
          <button
            class="w-full flex items-center justify-center bg-indigo-100 px-4 py-4 text-xl font-semibold rounded-3xl mt-4"
          >
            <BookOpenIcon class="h-6 w-6 mr-auto" aria-hidden="true" />
            <span class="mr-auto"> View Menu</span>
          </button>
        </div>
        <div class="px-4 py-4 shadow-sm border border-gray-100 rounded-xl">
          <div class="flex justify-between items-center">
            <span class="text-2xl font-semibold">Pay your bill</span>
            <span class="text-2xl font-semibold">£32.08</span>
          </div>
          <div class="text-center mb-4">
            <button
              class="w-full flex items-center justify-between bg-indigo-100 px-4 py-3 text-lg font-semibold rounded-xl mt-4"
            >
              <span class="">Share bill with your tablemates </span>
              <ClipboardIcon class="h-5 w-5 ml-auto" aria-hidden="true" />
            </button>
          </div>
          <hr class="w-full my-3" />
          <ItemsList></ItemsList>
        </div>
        <div class="text-center">
          <button
            class="w-full text-center bg-black text-white shadow-sm px-4 py-4 text-xl font-semibold rounded-full mt-4"
            @click="showPayBillModal = true"
          >
            <span class="mr-auto"> Pay or split bill</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <PayBillModal
    :show="showPayBillModal"
    @closePayBillModal="showPayBillModal = false"
  ></PayBillModal>
  <!-- </AppLayout> -->
</template>
<style scoped>
.top-banner {
  width: 100%;
  height: 0px;
  padding-top: 40%;
  border-radius: 0px 0px 24px 24px;
  background-image: url('http://static.apricot-it.com/dyn/cdn/10500/img/slider/background_image_1.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
