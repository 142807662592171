<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import { onBeforeMount, inject } from 'vue'
import Template1 from '@/layouts/mobileAppSite/template1/Template1.vue'

const restoStore = useRestoGLobalStore()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const router = useRouter()
onBeforeMount(() => {
  if (!IS_MOBILE_APP) {
    return router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
})
</script>
<template><component :is="Template1"></component></template>
