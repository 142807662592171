<script setup>
import { defineProps, ref } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { addMealItemFn } from '@/services/api/restApiCalls'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { CheckIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/vue/24/outline'
import { notify } from 'notiwind'

const props = defineProps(['mealItemData', 'productInfo', 'itemCount'])
const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const showMealModal = ref(false)
const itemQuantity = ref(1)
const mealParts = ref([])
const selectedMealArr = ref({})
const selectedMealParts = ref({})
const selectedAccesoryExtras = ref([])
const selectedOptionalExtra = ref({})
const currentMealParts = ref([])
const currentAccesoryExtra = ref([])
const tempSelectedMealItemParts = ref({})
const acccessoryExtraParts = ref([])
const currentOptionalExtra = ref([])
const mealPartsKeys = ref([])
const currentMealKey = ref('')

const handleMealModalOpen = (mealItem) => {
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
    return
  }
  mealParts.value = Object.values(mealItem?.meal_parts)
  mealPartsKeys.value = Object.keys(mealItem?.meal_parts)
  currentMealParts.value = mealParts.value.shift() || []
  showMealModal.value = true
}
const handleSelectedMealParts = (mealItem) => {
  selectedMealParts.value = mealItem
  currentMealKey.value = ''
  if (mealItem.accesory_extra_parts) {
    acccessoryExtraParts.value = Object.values(mealItem.accesory_extra_parts)
  }
  if (mealItem.optional_extras) {
    currentOptionalExtra.value = mealItem?.optional_extras
  }
  if (acccessoryExtraParts.value.length) {
    let remaining_extra_parts = acccessoryExtraParts.value
    let current_extra_part = acccessoryExtraParts.value.shift() || []
    currentAccesoryExtra.value = current_extra_part
    acccessoryExtraParts.value = remaining_extra_parts
    currentMealParts.value = []
  } else {
    currentMealParts.value = mealParts.value.shift() || []
  }
  currentMealKey.value = mealPartsKeys.value.shift() || []
  if (!mealItem.accesory_extra_parts) {
    selectedMealArr.value[`${mealItem.item_id}_${currentMealKey.value}`] = { ...mealItem }
  }
}
const handleSelectedAccessoryExtra = (extraItem) => {
  selectedAccesoryExtras.value = [...selectedAccesoryExtras.value, extraItem]
  let remaining_extra_parts = acccessoryExtraParts.value
  let current_extra_part = remaining_extra_parts.shift() || []
  currentAccesoryExtra.value = current_extra_part
  acccessoryExtraParts.value = remaining_extra_parts
  if (currentAccesoryExtra.value == 0) {
    tempSelectedMealItemParts.value = {
      item_id: selectedMealParts.value.item_id,
      sort_id: selectedMealParts.value.sort_id,
      item_name: selectedMealParts.value.item_name,
      item_name_full: selectedMealParts.value.item_name_full,
      item_syn: selectedMealParts.value.item_syn,
      optional_extras: [],
      accesory_extras: [...selectedAccesoryExtras.value]
    }
    selectedMealArr.value[`${tempSelectedMealItemParts.value.item_id}_${currentMealKey.value}`] = {
      ...tempSelectedMealItemParts.value
    }

    selectedAccesoryExtras.value = []
    if (currentOptionalExtra.value.length == 0) {
      currentMealParts.value = mealParts.value.shift() || []
    }
  }
}
const handleAddSelectedOptionalExtra = (optionalExtra) => {
  let extra_id = optionalExtra['extra_id']
  let selectedOptionalExtraObjTMP = selectedOptionalExtra.value
  if (selectedOptionalExtraObjTMP[extra_id]) {
    selectedOptionalExtraObjTMP[extra_id]['quantity'] += 1
  } else {
    optionalExtra['quantity'] = 1
    selectedOptionalExtraObjTMP[extra_id] = optionalExtra
  }
  selectedOptionalExtra.value = { ...selectedOptionalExtra.value, ...selectedOptionalExtraObjTMP }
}
const handleRemoveSelectedOptionExtra = (optionalExtra) => {
  let extra_id = optionalExtra['extra_id']
  let selectedOptionalExtraObjTMP = selectedOptionalExtra.value
  if (selectedOptionalExtraObjTMP[extra_id]) {
    selectedOptionalExtraObjTMP[extra_id]['quantity'] -= 1
    if (selectedOptionalExtraObjTMP[extra_id]['quantity'] === 0) {
      delete selectedOptionalExtraObjTMP[extra_id]
    }
  }
  selectedOptionalExtra.value = { ...selectedOptionalExtra.value, ...selectedOptionalExtraObjTMP }
}

const handleSkipOptionExtra = () => {
  currentOptionalExtra.value = []
  currentMealParts.value = mealParts.value.shift() || []
}

const handleConfirmOptionExtra = () => {
  const optionalExtrasArray = Object.values(selectedOptionalExtra.value)
  if (tempSelectedMealItemParts.value.optional_extras) {
    tempSelectedMealItemParts.value.optional_extras = [
      ...tempSelectedMealItemParts.value.optional_extras,
      ...optionalExtrasArray
    ]
  } else {
    tempSelectedMealItemParts.value.optional_extras = optionalExtrasArray
  }

  const createUniqueKey = (item) => {
    return `${item.item_id}_${currentMealKey.value}`
  }
  const tempItemKey = createUniqueKey(tempSelectedMealItemParts.value)
  let itemKey = ''
  const existingIndex = Object.keys(selectedMealArr.value).find((key) => {
    itemKey = createUniqueKey(selectedMealArr.value[key])
    return itemKey === tempItemKey
  })

  if (itemKey === tempItemKey) {
    selectedMealArr.value[tempItemKey] = { ...tempSelectedMealItemParts.value }
  } else {
    selectedMealArr.value[tempSelectedMealItemParts.value.item_id] = {
      ...tempSelectedMealItemParts.value
    }
  }
  selectedAccesoryExtras.value = []
  selectedOptionalExtra.value = {}
  currentOptionalExtra.value = []
  currentMealParts.value = mealParts.value.shift() || []
}

const handleItemQuantity = () => {
  if (itemQuantity.value > 1) {
    itemQuantity.value -= 1
  }
}
const handleAddMealItem = async (mealItem) => {
  const mealItemData = {
    action: 'add_meal_item',
    resto_id: restoStore.RESTO_ID,
    item_id: mealItem?.item_id,
    meal_parts: Object.values(selectedMealArr.value),
    quantity: itemQuantity.value
  }
  let mealItemFormData = new FormData()
  mealItemFormData.append('cart_data', JSON.stringify(mealItemData))
  const cart_info = await addMealItemFn(mealItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  handleMealModalClose()
  restoStore.hideModal()
  notify(
    {
      group: 'success',
      title: 'Added',
      text: `${mealItem?.item_name} added to cart`
    },
    2000
  )
}

const handleMealModalClose = () => {
  showMealModal.value = false
  itemQuantity.value = 1
  mealParts.value = []
  selectedMealArr.value = {}
  selectedMealParts.value = {}
  selectedAccesoryExtras.value = []
  selectedOptionalExtra.value = {}
  currentMealParts.value = []
  currentAccesoryExtra.value = []
  tempSelectedMealItemParts.value = {}
  acccessoryExtraParts.value = []
  currentOptionalExtra.value = []
}
</script>

<template>
  <div
    class="rounded-lg border border-gray-300 p-3 cursor-pointer hover:border-gray-400"
    @click="handleMealModalOpen(mealItemData)"
  >
    <div class="flex justify-between items-center">
      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
        {{ mealItemData?.item_name_full }}
      </h3>
      <div class="ml-3">
        <span> £{{ mealItemData?.price }} </span>
      </div>
    </div>
    <div v-if="itemCount == 1 && productInfo" class="mt-1">
      <p class="truncate text-sm text-gray-500 mb-0">{{ productInfo }}</p>
    </div>
    <!-- <div v-else class="mt-1">
      <p class="text-sm text-gray-500">{{ mealItemData?.item_name_full }}</p>
    </div> -->
  </div>

  <TransitionRoot as="template" :show="showMealModal">
    <Dialog as="div" class="relative z-20" @close="handleMealModalClose()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-20 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all my-8 w-full max-w-md p-6"
            >
              <div class="absolute top-0 right-0 pt-2 pr-2 block">
                <button
                  type="button"
                  class="rounded-full border border-e-violet p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
                  @click="handleMealModalClose()"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div class="max-h-90vh overflow-auto mb-8">
                <h2 as="h2" class="text-lg font-medium leading-6 text-gray-900 mb-3">
                  {{ mealItemData?.item_name_full }}
                </h2>
                <div
                  v-for="mealItem in Object.values(selectedMealArr)"
                  :key="mealItem.item_id"
                  class="mt-3 mb-3"
                >
                  <div class="bg-gray-200 border border-gray-300 p-2">
                    <div class="flex justify-between items-center">
                      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                        {{ mealItem.item_name_full }}
                      </h3>
                      <div class="ml-3">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </div>
                    </div>
                    <div
                      v-for="accExtra in mealItem.accesory_extras"
                      :key="accExtra.extra_id"
                      class=""
                    >
                      <div class="text-sm text-gray-600 font-normal">
                        + {{ accExtra.extra_name }}
                      </div>
                    </div>
                    <div v-if="mealItem?.optional_extras?.length" class="w-full text-gray-600">
                      <div
                        v-for="(optExtra, index) in mealItem?.optional_extras"
                        :key="optExtra.extra_id"
                      >
                        +{{
                          optExtra?.quantity + 'x' + optExtra?.extra_name + ' - £' + optExtra?.price
                        }}
                      </div>
                    </div>
                    <div v-if="mealItem.info" class="mt-1">
                      <p class="text-sm text-gray-500">{{ mealItem.info }}</p>
                    </div>
                  </div>
                </div>
                <div
                  v-for="selectedAccExtra in selectedAccesoryExtras"
                  :key="selectedAccExtra.extra_id"
                  class="mt-3 mb-3"
                >
                  <div class="bg-gray-200 border border-gray-300 p-2">
                    <div class="flex justify-between items-center">
                      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                        {{ selectedAccExtra.extra_name }}
                      </h3>
                      <div class="ml-3">
                        <h3
                          v-if="selectedAccExtra.price > 0"
                          class="text-md font-normal leading-6 text-gray-800 mr-4"
                        >
                          £{{ selectedAccExtra.price }}
                        </h3>
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </div>
                    </div>
                    <div v-if="selectedAccExtra.info" class="mt-1">
                      <p class="text-sm text-gray-500">{{ selectedAccExtra.info }}</p>
                    </div>
                  </div>
                </div>

                <div v-if="currentMealParts.length" class="text-lg text-blue-800 mb-1">
                  Choose Meal Item
                </div>
                <div
                  v-for="mealPart in currentMealParts"
                  :key="mealPart.extra_id"
                  class="mt-3 mb-3"
                >
                  <div
                    class="border-b border-gray-300 bg-white p-2 cursor-pointer"
                    @click="handleSelectedMealParts(mealPart)"
                  >
                    <div class="flex justify-between items-center">
                      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                        {{ mealPart.item_name }}
                      </h3>
                      <!-- <div v-if="mealPart.price" class="ml-3">
                        <span> £{{ mealPart.price }} </span>
                      </div> -->
                    </div>
                    <div v-if="mealPart.item_name_full" class="mt-1">
                      <p class="text-sm text-gray-500">{{ mealPart.item_name_full }}</p>
                    </div>
                  </div>
                </div>
                <div class="">
                  <h3
                    v-if="currentAccesoryExtra.length"
                    as="h3"
                    class="text-mf font-medium leading-6 text-gray-800 mb-3"
                  >
                    Select for {{ selectedMealParts.item_name_full }}
                  </h3>
                </div>
                <div v-if="currentAccesoryExtra.length" class="text-lg text-blue-800 mb-1">
                  Choose option
                </div>
                <div
                  v-for="accExtra in currentAccesoryExtra"
                  :key="accExtra.extra_id"
                  class="mt-3 mb-3"
                >
                  <div
                    class="border-b border-gray-300 bg-white p-2 cursor-pointer"
                    @click="handleSelectedAccessoryExtra(accExtra)"
                  >
                    <div class="flex justify-between items-center">
                      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                        {{ accExtra.extra_name }}
                      </h3>
                      <div v-if="accExtra.price > 0" class="ml-3">
                        <span> £{{ accExtra.price }} </span>
                      </div>
                    </div>
                    <div v-if="accExtra.info" class="mt-1">
                      <p class="text-sm text-gray-500">{{ accExtra.info }}</p>
                    </div>
                  </div>
                </div>
                <template v-if="currentAccesoryExtra.length == 0 && currentOptionalExtra?.length">
                  <div
                    v-for="optionExtra in currentOptionalExtra"
                    :key="optionExtra.extra_id"
                    class="mt-3 mb-3"
                  >
                    <div class="border-b border-gray-300 bg-white p-2 cursor-pointer">
                      <div class="flex justify-between items-center">
                        <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                          {{ optionExtra.extra_name }}
                        </h3>
                        <div v-if="optionExtra.price" class="ml-2 flex items-center">
                          <span> £{{ optionExtra.price }} </span>
                          <button
                            type="button"
                            class="rounded-full border border-transparent shadow-sm focus:outline-none focus:ring-2 ml-3"
                            @click="handleAddSelectedOptionalExtra(optionExtra)"
                          >
                            <PlusCircleIcon
                              class="h-7 w-7 text-e-violet mx-auto"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div v-if="optionExtra.info" class="mt-1">
                        <p class="text-sm text-gray-500">{{ optionExtra.info }}</p>
                      </div>
                      <div
                        v-for="optionalExtraItem in Object.values(selectedOptionalExtra)"
                        :key="optionalExtraItem.extra_id"
                        class="flex justify-between items-center mt-2"
                      >
                        <template v-if="optionExtra.extra_id == optionalExtraItem.extra_id">
                          <span>{{ optionalExtraItem.quantity }}</span>
                          <span
                            >£{{
                              (optionalExtraItem.price * optionalExtraItem.quantity).toFixed(2)
                            }}</span
                          >
                          <button
                            type="button"
                            class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2 ml-3"
                            @click="handleRemoveSelectedOptionExtra(optionExtra)"
                          >
                            <MinusCircleIcon
                              class="h-7 w-7 text-red-600 mx-auto"
                              aria-hidden="true"
                            />
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3" v-if="currentOptionalExtra.length">
                    <button
                      type="button"
                      v-if="Object.values(selectedOptionalExtra).length"
                      class="uppercase rounded border w-full border-gray-300 bg-violet-700 text-white px-2.5 py-1.5 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400"
                      @click="handleConfirmOptionExtra()"
                    >
                      Confirm Extras
                    </button>
                    <button
                      v-else
                      type="button"
                      class="uppercase rounded border w-full border-gray-300 bg-violet-700 text-white px-2.5 py-1.5 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400"
                      @click="handleSkipOptionExtra()"
                    >
                      Skip Extras
                    </button>
                  </div>
                </template>
              </div>
              <div
                class="fixed bottom-0 w-full z-50 left-0 right-0 mx-auto px-5 py-2 bg-white rounded-lg"
              >
                <div class="grid gap-4 grid-cols-2">
                  <div class="flex justify-between items-center mr-8">
                    <button
                      type="button"
                      :disabled="
                        mealParts.length != 0 ||
                        currentMealParts.length != 0 ||
                        currentAccesoryExtra.length != 0 ||
                        currentOptionalExtra.length != 0
                      "
                      class="rounded-full border border-transparent text-e-violet shadow-sm focus:outline-none focus:ring-2 disabled:text-gray-400"
                      @click="handleItemQuantity()"
                    >
                      <MinusCircleIcon class="h-6 w-6 mx-auto" aria-hidden="true" />
                    </button>
                    <span class="text-lg">{{ itemQuantity }}</span>
                    <button
                      type="button"
                      :disabled="
                        mealParts.length != 0 ||
                        currentMealParts.length != 0 ||
                        currentAccesoryExtra.length != 0 ||
                        currentOptionalExtra.length != 0
                      "
                      class="rounded-full border border-transparent text-e-violet shadow-sm focus:outline-none focus:ring-2 disabled:text-gray-400"
                      @click="itemQuantity += 1"
                    >
                      <PlusCircleIcon class="h-6 w-6 mx-auto" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="">
                    <button
                      type="button"
                      :disabled="
                        mealParts.length != 0 ||
                        currentMealParts.length != 0 ||
                        currentAccesoryExtra.length != 0 ||
                        currentOptionalExtra.length != 0
                      "
                      class="rounded border w-full border-gray-300 bg-violet-700 text-white px-2.5 py-1.5 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400"
                      @click="handleAddMealItem(mealItemData)"
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
