<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { ShoppingBagIcon } from '@heroicons/vue/20/solid'
import { BanknotesIcon, CreditCardIcon, ClockIcon } from '@heroicons/vue/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { getOrderStatusFn, reorderFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const restoStore = useRestoGLobalStore()
const getOrderId = ref(route.params.order_id)
const getOrderData = ref({})
const orderStatusInterval = ref('')
const handleOrderStatus = async () => {
  // getOrderId.value = route.params.order_id
  if (getOrderId.value) {
    const order_status = await getOrderStatusFn(getOrderId.value, restoStore?.RESTO_ID)
    if (order_status) {
      getOrderData.value = order_status
    } else {
      router.push({ name: 'NotFound' })
    }
  }
}
const handleReorder = async () => {
  let reorderFormData = new FormData()
  reorderFormData.append('order_id', getOrderId.value)
  reorderFormData.append('resto_id', restoStore?.RESTO_ID)
  const order_data = await reorderFn(reorderFormData)
  if (order_data.status == 201) {
    router.push({ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } })
  }
}
onMounted(() => {
  handleOrderStatus()
  orderStatusInterval.value = setInterval(() => {
    if (getOrderData?.value.order_status == 'AUTHORIZED') {
      handleOrderStatus()
    } else {
      clearInterval(orderStatusInterval.value)
    }
  }, 10000)
})
onBeforeUnmount(() => {
  clearInterval(orderStatusInterval.value)
})
</script>
<template>
  <AppLayout>
    <div class="bg-gray-50">
      <div class="mx-auto max-w-full lg:max-w-5xl md:px-6 sm:px-3 px-2 py-8">
        <div
          class="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0"
        >
          <div class="flex sm:items-baseline sm:space-x-4">
            <h1 class="md:text-xl text-lg font-semibold text-gray-900 mb-2">
              Your Order no: {{ getOrderData?.order_id }}
            </h1>
          </div>
          <p class="text-sm text-gray-600">
            Order placed
            <time datetime="2021-03-22" class="font-medium text-gray-900">{{
              getOrderData?.creation_datetime
            }}</time>
          </p>
        </div>

        <div class="mt-6">
          <div class="space-y-8">
            <div
              class="border-t border-b border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
            >
              <div v-if="getOrderData?.order_status == 'AUTHORIZED'" class="space-y-4 pt-8">
                <div class="text-center sm:w-4/5 md:w-3/5 w-full mx-auto">
                  <h3 class="md:text-xl text-base font-semibold text-gray-900 mb-1">
                    Thank you for your order
                  </h3>
                  <h3 class="md:text-lg text-base font-semibold text-gray-900 mb-2 leading-tight">
                    Please be patient, it might take few mins for restaurant to accept your order.
                  </h3>
                  <h4 class="md:text-xl text-lg font-semibold text-yellow-500 mb-2">
                    Waiting for confirmation
                  </h4>
                  <div class="text-center">
                    <img
                      class="block mx-auto md:w-20 sm:w-16 w-16"
                      src="./../../assets/images/loader.gif"
                    />
                  </div>
                </div>
              </div>
              <div v-if="getOrderData?.order_status == 'SUCCESS'" class="pt-8">
                <div class="text-center w-4/5 mx-auto">
                  <CheckCircleIcon class="h-14 w-14 text-green-500 mx-auto" aria-hidden="true" />
                  <h3 class="text-xl font-semibold text-green-500 mb-2">Accepted</h3>
                  <div class="flex justify-center">
                    <ClockIcon class="h-5 w-5 mr-1" aria-hidden="true" />
                    <h3 class="text-lg font-semibold text-gray-900 mb-2 leading-tight">
                      Your order will be ready in
                      {{
                        getOrderData?.exp_collection_time_mins ||
                        getOrderData?.exp_delivery_time_mins
                      }}
                      mins
                    </h3>
                  </div>
                  <!-- <h4 class="text-lg font-semibold text-gray-900 mb-2">
                    Placed on {{ getOrderData?.creation_datetime }}
                  </h4> -->
                </div>
              </div>
              <div v-if="getOrderData?.order_status == 'FAILURE'" class="pt-8">
                <div class="text-center w-4/5 mx-auto">
                  <XCircleIcon class="h-12 w-12 text-red-500 mx-auto" aria-hidden="true" />
                  <h3 class="text-xl font-semibold text-red-600 mb-2">
                    {{ getOrderData.display_card_payment_error_message }}<br />
                    You have not been charged.
                  </h3>
                  <h3 class="text-xl font-semibold text-green-500 mb-2 leading-tight">
                    Please place new order
                  </h3>
                  <div class="flex justify-center text-center px-2">
                    <button
                      type="submit"
                      class="md:w-1/4 w-full mr-2 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400"
                      @click="handleReorder()"
                    >
                      Reorder
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="getOrderData?.order_status == 'CANCELLED'" class="pt-8">
                <div class="text-center w-4/5 mx-auto">
                  <XCircleIcon class="h-14 w-14 text-red-500 mx-auto" aria-hidden="true" />
                  <h3 class="text-xl font-semibold text-red-600 mb-2">
                    {{ getOrderData?.resto_note }}
                  </h3>
                </div>
              </div>
              <div class="py-6 px-4 sm:px-6 md:grid md:grid-cols-12 md:gap-x-8 md:p-6">
                <div class="md:col-span-6">
                  <div class="flex items-center justify-between mb-3">
                    <dt class="text-gray-900 font-semibold sm:text-base text-sm">Total</dt>
                    <dd class="font-normal text-gray-900 sm:text-base text-sm">
                      £{{ getOrderData?.amount || 0 }}
                    </dd>
                  </div>
                  <div class="flex items-center justify-between mb-3">
                    <dt class="text-gray-900 font-semibold sm:text-base text-sm">Order Type</dt>
                    <dd>
                      <div
                        class="text-sm font-normal flex items-center px-4 py-1 text-white rounded-full capitalize"
                        :class="[
                          getOrderData?.order_type == 'delivery' ? 'bg-green-600' : 'bg-violet-600'
                        ]"
                      >
                        <ShoppingBagIcon
                          v-if="getOrderData?.order_type == 'collection'"
                          class="h-4 w-4 mr-1 text-white mx-auto"
                          aria-hidden="true"
                        />
                        <svg
                          v-else
                          class="h-4 w-4 text-white fill-current mr-1"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                          ></path>
                        </svg>
                        {{ getOrderData?.order_type }}
                      </div>
                    </dd>
                  </div>
                  <div class="flex items-center justify-between mb-3">
                    <dt class="text-gray-900 font-semibold sm:text-base text-sm">Payment Type</dt>
                    <dd
                      class="text-sm font-normal flex items-center px-4 py-1 text-white rounded-full capitalize"
                      :class="[
                        getOrderData?.payment_type == 'cash' ? 'bg-yellow-600' : 'bg-purple-600'
                      ]"
                    >
                      <BanknotesIcon
                        v-if="getOrderData?.payment_type == 'cash'"
                        class="h-4 w-4 mr-1 text-white mx-auto"
                        aria-hidden="true"
                      />
                      <CreditCardIcon
                        v-else
                        class="h-4 w-4 mr-1 text-white mx-auto"
                        aria-hidden="true"
                      />
                      {{ getOrderData?.payment_type }}
                    </dd>
                  </div>
                </div>
                <div class="md:col-span-3"></div>
                <div v-if="getOrderData?.order_type == 'delivery'" class="md:col-span-3">
                  <div class="flex justify-between md:block">
                    <dt class="font-medium text-gray-900 mb-3 sm:text-base text-sm">
                      Delivery address
                    </dt>
                    <dd class="text-gray-500 sm:text-base text-sm">
                      <span class="block">{{
                        getOrderData?.customer?.address?.address_line1 +
                        ', ' +
                        getOrderData?.customer?.address?.address_line2
                      }}</span>
                      <span class="block">{{
                        getOrderData?.customer?.address?.country_code +
                        ', ' +
                        getOrderData?.customer?.address?.post_code
                      }}</span>
                    </dd>
                  </div>
                </div>
              </div>

              <div class="border-t border-gray-200 py-4 px-4 sm:px-6">
                <div class="mx-auto text-right flex justify-end items-center">
                  <button
                    type="submit"
                    class="sm:w-1/4 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                    @click="
                      router.push({
                        name: 'view_order',
                        params: { order_id: `${getOrderData?.order_id}` }
                      })
                    "
                  >
                    Show Order
                  </button>
                  <button
                    type="submit"
                    class="sm:w-1/4 ml-2 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                    @click="$router.push({ name: 'my_orders' })"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
