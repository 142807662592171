<script setup>
import { defineProps } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { addSimpleItemFn } from '@/services/api/restApiCalls'
import { notify } from 'notiwind'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const props = defineProps(['simpleItemData', 'productInfo', 'itemCount'])
const handleAddSimpleItem = async (simpleItem) => {
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
  } else {
    const simpleItemData = {
      action: 'add',
      resto_id: restoStore?.RESTO_ID,
      item_id: simpleItem.item_id
    }
    let simpleItemFormData = new FormData()
    simpleItemFormData.append('cart_data', JSON.stringify(simpleItemData))
    const cart_info = await addSimpleItemFn(simpleItemFormData)
    cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
    cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
    restoStore.hideModal()
    notify(
      {
        group: 'success',
        title: 'Added',
        text: `${simpleItem?.item_name} added to cart`
      },
      2000
    )
  }
}
</script>

<template>
  <div
    class="rounded-lg border border-gray-300 p-3 cursor-pointer hover:border-gray-400"
    @click="handleAddSimpleItem(simpleItemData)"
  >
    <div class="flex justify-between items-center">
      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
        {{ simpleItemData?.item_name_full }}
      </h3>
      <div class="ml-3">
        <span> £{{ simpleItemData?.price }} </span>
      </div>
    </div>
    <div v-if="itemCount == 1 && productInfo" class="mt-1">
      <p class="truncate text-sm text-gray-500 mb-0">{{ productInfo }}</p>
    </div>
    <!-- <div v-else class="mt-1">
      <p class="text-sm text-gray-500">{{ simpleItemData?.item_name_full }}</p>
    </div> -->
  </div>
</template>
