<script setup>
import { ref, watchEffect } from 'vue'
import { Countdown } from 'vue3-flip-countdown'
// import { useCartGLobalStore } from '@/stores/cartGlobalStore'

// const cartStore = useCartGLobalStore()
const props = defineProps(['restoStatus'])
const isRestoOpened = ref(false)
const formattedDate = ref()
watchEffect(async () => {
  if (props.restoStatus) {
    let openingTime = props?.restoStatus?.opening_time_till_today
    if (openingTime) {
      formattedDate.value = openingTime.replace(/\//g, '-')
    }
  }
})
const handleTimeElapsed = () => {
  isRestoOpened.value = true
}
</script>
<template>
  <div
    v-if="
      !props?.restoStatus?.is_open &&
      props?.restoStatus?.opening_time_till_today !== null &&
      !isRestoOpened
    "
    class="p-2 mb-1 text-center flex justify-center items-center bg-red-500"
  >
    <div class="md:text-2xl text-xl text-white">Restaurant open in</div>
    <div v-if="formattedDate">
      <Countdown
        :showDays="false"
        countdownSize="1.8rem"
        labelSize=".9rem"
        labelColor="white"
        :deadline="formattedDate"
        @timeElapsed="handleTimeElapsed"
      ></Countdown>
    </div>
  </div>
</template>
<style scoped>
.flip-clock {
  margin: 0 !important;
}
</style>
<!-- :deadline="formattedDate" -->
