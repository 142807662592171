<script setup>
import { defineProps, ref } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { addComplexItemFn } from '@/services/api/restApiCalls'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { CheckIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/vue/24/outline'
import { notify } from 'notiwind'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const props = defineProps(['complexItemData', 'productInfo', 'itemCount'])
const acccessoryExtraParts = ref([])
const selectedAccesoryExtras = ref([])
const selectedOptionalExtra = ref({})
let currentAccesoryExtra = ref([])
let currentOptionalExtra = ref([])
let itemQuantity = ref(1)
const showComplexModal = ref(false)

const handleComplexModalOpen = async (complexItem) => {
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
    return
  }
  if (complexItem.accesory_extra_parts) {
    acccessoryExtraParts.value = Object.values(complexItem?.accesory_extra_parts)
    currentAccesoryExtra.value = acccessoryExtraParts.value.shift() || []
  }
  if (complexItem.optional_extras) {
    currentOptionalExtra.value = complexItem?.optional_extras
  }
  showComplexModal.value = true
}

const handleSelectedAccessoryExtra = (extraItem) => {
  selectedAccesoryExtras.value = [...selectedAccesoryExtras.value, extraItem]
  let remaining_extra_parts = acccessoryExtraParts.value
  let current_extra_part = remaining_extra_parts.shift() || []
  currentAccesoryExtra.value = current_extra_part
  acccessoryExtraParts.value = remaining_extra_parts
}
const handleAddSelectedOptionalExtra = (optionalExtra) => {
  let extra_id = optionalExtra['extra_id']
  let selectedOptionalExtraObjTMP = selectedOptionalExtra.value
  if (selectedOptionalExtraObjTMP[extra_id]) {
    selectedOptionalExtraObjTMP[extra_id]['quantity'] += 1
  } else {
    optionalExtra['quantity'] = 1
    selectedOptionalExtraObjTMP[extra_id] = optionalExtra
  }
  selectedOptionalExtra.value = { ...selectedOptionalExtra.value, ...selectedOptionalExtraObjTMP }
}
const handleRemoveSelectedOptionExtra = (optionalExtra) => {
  let extra_id = optionalExtra['extra_id']
  let selectedOptionalExtraObjTMP = selectedOptionalExtra.value
  if (selectedOptionalExtraObjTMP[extra_id]) {
    selectedOptionalExtraObjTMP[extra_id]['quantity'] -= 1
    if (selectedOptionalExtraObjTMP[extra_id]['quantity'] === 0) {
      delete selectedOptionalExtraObjTMP[extra_id]
    }
  }
  selectedOptionalExtra.value = { ...selectedOptionalExtra.value, ...selectedOptionalExtraObjTMP }
}
const handleAddComplexItem = async (complexItem) => {
  const complexItemData = {
    action: 'add_complex_item',
    resto_id: restoStore?.RESTO_ID,
    item_id: complexItem?.item_id,
    optional_extras: Object.values(selectedOptionalExtra.value),
    accesory_extras: selectedAccesoryExtras.value,
    quantity: itemQuantity.value
  }
  let complexItemFormData = new FormData()
  complexItemFormData.append('cart_data', JSON.stringify(complexItemData))
  const cart_info = await addComplexItemFn(complexItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  restoStore.hideModal()
  handleComplexModalClose()
  notify(
    {
      group: 'success',
      title: 'Added',
      text: `${complexItem?.item_name} added to cart`
    },
    2000
  )
}
const handleComplexModalClose = () => {
  currentAccesoryExtra.value = []
  acccessoryExtraParts.value = []
  selectedAccesoryExtras.value = []
  currentOptionalExtra.value = []
  selectedOptionalExtra.value = {}
  showComplexModal.value = false
}

const handleItemQuantity = () => {
  if (itemQuantity.value > 1) {
    itemQuantity.value -= 1
  }
}
</script>

<template>
  <div
    class="rounded-lg border border-gray-300 p-3 cursor-pointer hover:border-gray-400"
    @click="handleComplexModalOpen(complexItemData)"
  >
    <div class="flex justify-between items-center">
      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
        {{ complexItemData?.item_name_full }}
      </h3>
      <div class="ml-3">
        <span> £{{ complexItemData?.price }} </span>
      </div>
    </div>
    <div v-if="itemCount == 1 && productInfo" class="mt-1">
      <p class="truncate text-sm text-gray-500 mb-0">{{ productInfo }}</p>
    </div>
    <!-- <div v-else class="mt-1">
      <p class="text-sm text-gray-500">{{ complexItemData?.item_name_full }}</p>
    </div> -->
  </div>

  <TransitionRoot as="template" :show="showComplexModal">
    <Dialog as="div" class="relative z-20" @close="handleComplexModalClose()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-20 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all my-8 w-full max-w-md p-6"
            >
              <div class="absolute top-0 right-0 pt-2 pr-2 block">
                <button
                  type="button"
                  class="rounded-full border border-e-violet p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
                  @click="handleComplexModalClose()"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div class="max-h-90vh overflow-auto mb-8">
                <h2 as="h2" class="text-lg font-medium leading-6 text-gray-900 mb-3">
                  {{ complexItemData?.item_name }}
                </h2>
                <div
                  v-for="selectedAccExtra in selectedAccesoryExtras"
                  :key="selectedAccExtra.extra_id"
                  class="mt-3 mb-3"
                >
                  <div class="bg-gray-200 border border-gray-300 p-2">
                    <div class="flex justify-between items-center">
                      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                        {{ selectedAccExtra.extra_name }}
                      </h3>
                      <div class="flex ml-3">
                        <h3
                          v-if="selectedAccExtra.price > 0"
                          class="text-md font-normal leading-6 text-gray-800 mr-4"
                        >
                          £{{ selectedAccExtra.price }}
                        </h3>
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </div>
                    </div>
                    <div v-if="selectedAccExtra.info" class="mt-1">
                      <p class="text-sm text-gray-500">{{ selectedAccExtra.info }}</p>
                    </div>
                  </div>
                </div>
                {{ currentAccesoryExtra.value }}
                <div v-if="currentAccesoryExtra.length" class="text-lg text-blue-800 mb-1">
                  Choose option
                </div>
                <div
                  v-for="accExtra in currentAccesoryExtra"
                  :key="accExtra.extra_id"
                  class="mt-3 mb-3"
                >
                  <div
                    class="border-b border-gray-300 bg-white p-2 cursor-pointer"
                    @click="handleSelectedAccessoryExtra(accExtra)"
                  >
                    <div class="flex justify-between items-center">
                      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                        {{ accExtra.extra_name }}
                      </h3>
                      <div v-if="accExtra.price > 0" class="ml-3">
                        <span> £{{ accExtra.price }} </span>
                      </div>
                    </div>
                    <div v-if="accExtra.info" class="mt-1">
                      <p class="text-sm text-gray-500">{{ accExtra.info }}</p>
                    </div>
                  </div>
                </div>
                <template v-if="currentAccesoryExtra.length == 0">
                  <div v-if="currentOptionalExtra.length" class="text-lg text-blue-800 mb-1">
                    Choose Extra
                  </div>
                  <div
                    v-for="optionExtra in currentOptionalExtra"
                    :key="optionExtra.$index"
                    class="mt-3 mb-3"
                    track-by="$index"
                  >
                    <div v-if="optionExtra != null" class="border-b border-gray-300 bg-white p-2">
                      <div class="flex justify-between items-center">
                        <h3 as="h3" class="text-md font-medium leading-6 text-gray-900">
                          {{ optionExtra.extra_name }}
                        </h3>
                        <div v-if="optionExtra.price" class="ml-2 flex items-center">
                          <span> £{{ optionExtra.price }} </span>
                          <button
                            type="button"
                            class="rounded-full border border-transparent shadow-sm focus:outline-none focus:ring-2 ml-3"
                            @click.stop="handleAddSelectedOptionalExtra(optionExtra)"
                          >
                            <PlusCircleIcon
                              class="h-7 w-7 text-e-violet mx-auto"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div v-if="optionExtra.info" class="mt-1">
                        <p class="text-sm text-gray-500">{{ optionExtra.info }}</p>
                      </div>
                      <div
                        v-for="optionalExtraItem in Object.values(selectedOptionalExtra)"
                        :key="optionalExtraItem.extra_id"
                        class="flex justify-between items-center mt-2"
                        track-by="$index"
                      >
                        <template v-if="optionExtra.extra_id == optionalExtraItem.extra_id">
                          <span>{{ optionalExtraItem.quantity }}</span>
                          <span
                            >£{{
                              (optionalExtraItem.price * optionalExtraItem.quantity).toFixed(2)
                            }}</span
                          >
                          <button
                            type="button"
                            class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2 ml-3"
                            @click="handleRemoveSelectedOptionExtra(optionExtra)"
                          >
                            <MinusCircleIcon
                              class="h-7 w-7 text-red-600 mx-auto"
                              aria-hidden="true"
                            />
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="fixed bottom-0 w-full z-50 left-0 right-0 mx-auto px-5 py-2 bg-white rounded-lg"
              >
                <div class="grid grid-cols-1 gap-4 grid-cols-2">
                  <div class="flex justify-between items-center mr-8">
                    <button
                      type="button"
                      :disabled="currentAccesoryExtra.length != 0"
                      class="rounded-full border border-transparent text-e-violet shadow-sm focus:outline-none focus:ring-2 disabled:text-gray-400"
                      @click="handleItemQuantity()"
                    >
                      <MinusCircleIcon class="h-6 w-6 mx-auto" aria-hidden="true" />
                    </button>
                    <span class="text-lg">{{ itemQuantity }}</span>
                    <button
                      type="button"
                      :disabled="currentAccesoryExtra.length != 0"
                      class="rounded-full border border-transparent text-e-violet shadow-sm focus:outline-none focus:ring-2 disabled:text-gray-400"
                      @click="itemQuantity += 1"
                    >
                      <PlusCircleIcon class="h-6 w-6 mx-auto" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="">
                    <button
                      type="button"
                      :disabled="currentAccesoryExtra.length != 0"
                      class="rounded border w-full border-gray-300 bg-violet-700 text-white px-2.5 py-1.5 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400"
                      @click="handleAddComplexItem(complexItemData)"
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
