<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ShoppingBagIcon } from '@heroicons/vue/20/solid'
import { BanknotesIcon, CreditCardIcon } from '@heroicons/vue/24/outline'
import { reorderFn, getOrderStatusFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'

const restoStore = useRestoGLobalStore()
const router = useRouter()
const route = useRoute()
const orderId = ref(route.params.order_id)
const orderDetailData = ref({})

const getOrderDetail = async () => {
  if (orderId.value) {
    const order_status = await getOrderStatusFn(orderId.value, restoStore?.RESTO_ID)
    if (order_status.order_id == orderId.value) {
      orderDetailData.value = order_status
    } else {
      router.push({ name: 'my_orders' })
    }
  } else {
    router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
}
const handleReorder = async () => {
  let reorderFormData = new FormData()
  reorderFormData.append('order_id', orderId.value)
  reorderFormData.append('resto_id', restoStore?.RESTO_ID)
  const order_data = await reorderFn(reorderFormData)
  if (order_data?.status == 201) {
    router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
}
onMounted(() => {
  getOrderDetail()
})
</script>
<template>
  <AppLayout>
    <div class="container mx-auto md:pt-12 pt-8">
      <div class="mb-2">
        <div class="sm:flex-auto text-center md:mb-6 mb-4 px-2">
          <h1 class="text-xl font-semibold text-gray-900 mb-2">
            Your Order No : {{ orderDetailData?.order_id }}
          </h1>
          <p>Placed on: {{ orderDetailData?.creation_datetime }}</p>
        </div>
        <div class="flex justify-center text-center px-2">
          <button
            type="submit"
            class="md:w-1/4 w-full mr-2 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
            @click="handleReorder()"
          >
            Reorder
          </button>
          <button
            type="submit"
            class="md:w-1/4 w-full ml-2 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
            @click="$router.push({ name: 'my_orders' })"
          >
            Back
          </button>
        </div>
      </div>
      <div class="mt-4 lg:mt-6">
        <div
          class="font-semibold block md:text-lg text-red-500 mb-3 text-center"
          v-if="
            orderDetailData?.order_status == 'FAILURE' && orderDetailData?.payment_type == 'card'
          "
        >
          {{ orderDetailData?.display_card_payment_error_message }}
        </div>
        <div class="pb-4 sm:px-2 px-1 lg:grid lg:grid-cols-12 lg:gap-x-6 lg:px-0 lg:pb-8">
          <dl
            class="divide-y divide-gray-200 text-sm lg:col-span-6 bg-white border border-gray-200 rounded-sm sm:p-4 px-2 py-3 md:p-6"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="pb-3.5 pl-0 md:pr-3 sm:pr-2 pr-1 text-left md:text-lg text-md font-semibold text-gray-900"
                  >
                    Item Name
                  </th>
                  <th
                    scope="col"
                    class="md:px-3 sm:px-2 px-1 pb-3.5 text-left md:text-lg text-md font-semibold text-gray-900 lg:table-cell"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    class="md:px-3 sm:px-2 px-1 pb-3.5 text-right md:text-lg text-md font-semibold text-gray-900"
                  >
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="order in orderDetailData?.order_items" :key="order.item_id">
                  <td class="py-3 md:pr-3 sm:pr-2 pr-1 text-sm font-medium text-gray-900 pl-0">
                    <span class="font-semibold block">{{ order.item_name }}</span>
                    <span class="font-normal block" v-html="order.order_item_html"></span>
                  </td>
                  <td
                    class="whitespace-nowrap md:px-3 sm:px-2 px-1 py-3 text-sm text-gray-900 sm:table-cell"
                  >
                    {{ order.quantity }}
                  </td>
                  <td
                    class="whitespace-nowrap md:px-3 sm:px-2 px-1 py-3 text-sm text-right text-gray-900 lg:table-cell"
                  >
                    £{{ order.price }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex items-center justify-between ms:py-4 py-3">
              <dt class="text-gray-900 font-semibold">Subtotal</dt>
              <dd class="font-medium text-gray-900 md:px-3 sm:px-2 px-1">
                £{{ orderDetailData?.amount }}
              </dd>
            </div>
            <div
              v-if="orderDetailData?.discount_amount > 0"
              class="flex items-center justify-between ms:py-4 py-3"
            >
              <dt class="text-red-500 font-semibold">Discount</dt>
              <dd class="font-medium text-red-500 md:px-3 sm:px-2 px-1">
                £-{{ orderDetailData?.discount_amount }}
              </dd>
            </div>
            <div
              v-if="orderDetailData?.order_type == 'delivery'"
              class="flex items-center justify-between ms:py-4 py-3"
            >
              <dt class="text-gray-900 font-semibold">Delivery Fee</dt>
              <dd class="font-medium text-gray-900 md:px-3 sm:px-2 px-1">
                £{{ orderDetailData?.delivery_amount }}
              </dd>
            </div>
            <div
              v-if="orderDetailData?.service_charge_fee > 0"
              class="flex items-center justify-between ms:py-4 py-3"
            >
              <dt class="text-gray-900 font-semibold">Service Charge</dt>
              <dd class="font-medium text-gray-900 md:px-3 sm:px-2 px-1">
                £{{ orderDetailData?.service_charge_fee }}
              </dd>
            </div>
            <div class="flex items-center justify-between ms:py-4 py-3">
              <dt class="text-gray-900 font-semibold">Total</dt>
              <dd class="font-medium text-gray-900 md:px-3 sm:px-2 px-1">
                £{{ orderDetailData?.amount }}
              </dd>
            </div>
          </dl>
          <hr class="lg:hidden block" />
          <dl
            class="md:mt-8 mt-4 divide-y divide-gray-200 text-sm lg:col-span-6 lg:mt-0 bg-white border border-gray-200 rounded-sm sm:p-4 px-2 py-3 md:p-6"
          >
            <div class="flex items-center justify-between pb-3">
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Customer Name</dt>
              <dd class="sm:font-medium font-normal text-gray-900 capitalize text-base">
                {{
                  orderDetailData?.customer?.first_name + ' ' + orderDetailData?.customer?.last_name
                }}
              </dd>
            </div>
            <div class="flex items-center justify-between py-3">
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Order Status</dt>
              <dd
                v-if="orderDetailData?.order_status == 'FAILURE'"
                class="font-medium text-red-600"
              >
                <span class="font-semibold block md:text-base"
                  >{{ orderDetailData?.display_card_payment_error_message }}
                </span>
                <span class="font-semibold block md:text-base">You have not been charged.</span>
              </dd>
              <dd
                v-else
                class="font-medium"
                :class="{
                  'text-red-600':
                    orderDetailData?.order_status == 'AUTHORIZED' ||
                    orderDetailData?.order_status == 'CANCELLED',
                  'text-green-600': orderDetailData?.order_status == 'SUCCESS'
                }"
              >
                {{ orderDetailData?.order_status }}
              </dd>
            </div>
            <div class="flex items-center justify-between py-3">
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Order Type</dt>
              <dd class="sm:font-medium font-normal text-gray-900 capitalize">
                <span
                  class="flex items-center px-4 py-1 text-white rounded-full"
                  :class="[
                    orderDetailData?.order_type == 'delivery' ? 'bg-green-600' : 'bg-violet-600'
                  ]"
                >
                  <span v-if="orderDetailData?.order_type == 'collection'" class="mr-1.5">
                    <ShoppingBagIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                  </span>
                  <span v-if="orderDetailData?.order_type == 'delivery'" class="mr-1.5">
                    <svg
                      class="h-4 w-4 text-white fill-current"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                      ></path>
                    </svg>
                  </span>
                  {{ orderDetailData?.order_type }}
                </span>
              </dd>
            </div>
            <div class="flex items-center justify-between py-3">
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Payment Type</dt>
              <dd class="sm:font-medium font-normal text-gray-900 capitalize">
                <span
                  class="flex items-center px-4 py-1 text-white rounded-full"
                  :class="[
                    orderDetailData?.payment_type == 'cash' ? 'bg-yellow-600' : 'bg-purple-600'
                  ]"
                >
                  <span v-if="orderDetailData?.payment_type == 'cash'" class="mr-1.5">
                    <BanknotesIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                  </span>
                  <span v-if="orderDetailData?.payment_type == 'card'" class="mr-1.5">
                    <CreditCardIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                  </span>
                  {{ orderDetailData?.payment_type }}
                </span>
              </dd>
            </div>
            <!-- <div
              v-if="
                orderDetailData.payment_error_code ||
                orderDetailData.payment_error_decline_code ||
                orderDetailData.payment_error_message ||
                orderDetailData.payment_error_type
              "
              class="flex items-center justify-between py-3"
            >
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Payment Error</dt>
              <dd
                v-if="orderDetailData.payment_error_code"
                class="sm:font-medium font-normal text-red-600"
              >
                {{ orderDetailData?.payment_error_code }}
              </dd>
              <dd
                v-if="orderDetailData.payment_error_decline_code"
                class="sm:font-medium font-normal text-red-600"
              >
                {{ orderDetailData?.payment_error_decline_code }}
              </dd>
              <dd
                v-if="orderDetailData.payment_error_message"
                class="sm:font-medium font-normal text-red-600"
              >
                {{ orderDetailData?.payment_error_message }}
              </dd>
              <dd
                v-if="orderDetailData.payment_error_type"
                class="sm:font-medium font-normal text-red-600"
              >
                {{ orderDetailData?.payment_error_type }}
              </dd>
            </div> -->
            <div
              v-if="orderDetailData.refunded_amount > 0"
              class="flex items-center justify-between py-3"
            >
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Refunded amount</dt>
              <dd class="sm:font-medium font-normal text-red-600">
                £{{ orderDetailData?.refunded_amount }}
              </dd>
            </div>
            <div
              v-if="orderDetailData?.voucher?.code"
              class="flex items-center justify-between py-3"
            >
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Voucher code</dt>
              <dd class="sm:font-medium font-normal text-gray-900 text-base">
                {{ orderDetailData?.voucher?.code }}
              </dd>
            </div>
            <div v-if="orderDetailData?.note" class="flex items-center justify-between py-3">
              <dt class="text-gray-700 font-semibold md:text-lg text-md">Comment</dt>
              <dd class="sm:font-medium font-normal text-gray-900">{{ orderDetailData?.note }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
