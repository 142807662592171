<script>
import AppLayout from '@/layouts/AppLayout.vue'
import SettingMenuLink from '@/views/account/setting/common/SettingMenuLink.vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { changePasswordFn } from '@/services/api/restApiCalls'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    SettingMenuLink,
    LoadingSpinner
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()
    const password = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const passwordValid = ref(false)
    const submitted = ref(false)
    const passwordData = ref({})
    const isLoading = ref(false)

    const validationRules = {
      password: { required },
      newPassword: { required, minLength: minLength(5) },
      confirmNewPassword: { required, sameAs: sameAs(newPassword) }
    }
    const v$ = useVuelidate(validationRules, {
      password,
      newPassword,
      confirmNewPassword
    })
    const handleUpdatePassword = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      let passwordFormData = new FormData()
      passwordFormData.append('password', password.value)
      passwordFormData.append('password_new', newPassword.value)
      passwordFormData.append('password_new_confirm', confirmNewPassword.value)
      const change_password = await changePasswordFn(passwordFormData)
      passwordData.value = change_password
      // passwordValid.value = true
      isLoading.value = false
      if (passwordData?.value.status == 200) {
        notify(
          {
            group: 'successmessage',
            title: 'Success',
            text: `Settings updated successfully`
          },
          2000
        )
      } else {
        notify(
          {
            group: 'errormessage',
            title: 'Error',
            text: `${passwordData?.value.status_msg}`
          },
          2000
        )
      }
    }
    watch([password, newPassword, confirmNewPassword], () => {
      passwordData.value = ''
    })
    return {
      restoStore,
      router,
      password,
      newPassword,
      confirmNewPassword,
      passwordValid,
      submitted,
      v$,
      passwordData,
      handleUpdatePassword,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <SettingMenuLink></SettingMenuLink>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0 dark:bg-gray-900"
    >
      <div class="w-full max-w-xl p-6 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <h2 class="text-2xl font-bold text-gray-900 dark:text-white text-center mb-8">
          Change Password
        </h2>

        <div class="">
          <form class="space-y-4" @submit.prevent="handleUpdatePassword">
            <div v-if="passwordData?.status == 200" class="rounded-md bg-green-50 p-4">
              <div class="flex justify-center items-center">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="lg:h-8 lg:w-8 h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-2">
                  <p class="text-md font-medium text-green-800">Password Updated succesfully</p>
                </div>
              </div>
            </div>
            <div v-if="passwordData?.status == 400" class="rounded-md bg-red-50 p-4">
              <div class="flex justify-center items-center">
                <div class="flex-shrink-0">
                  <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-2">
                  <p class="text-md font-medium text-red-800">{{ passwordData?.status_msg }}</p>
                </div>
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
              <div class="mt-1">
                <input
                  id="password"
                  v-model="password"
                  name="password"
                  placeholder="Enter current password"
                  type="password"
                  autocomplete="current-password"
                  :class="{ 'border-red-600': submitted && v$.password.$error }"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                />
              </div>
              <div v-for="(error, index) of v$.password.$errors" :key="index" class="input-errors">
                <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
              </div>
            </div>

            <div>
              <label for="newPassword" class="block text-sm font-medium text-gray-700"
                >New Password</label
              >
              <div class="mt-1">
                <input
                  id="newPassword"
                  v-model="newPassword"
                  name="newPassword"
                  placeholder="Enter new password"
                  type="password"
                  autocomplete="new-password"
                  :class="{ 'border-red-600': submitted && v$.newPassword.$error }"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                />
              </div>
              <div
                v-for="(error, index) of v$.newPassword.$errors"
                :key="index"
                class="input-errors"
              >
                <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
              </div>
            </div>

            <div>
              <label for="confirmNewPassword" class="block text-sm font-medium text-gray-700"
                >Confirm New Password</label
              >
              <div class="mt-1">
                <input
                  id="confirmNewPassword"
                  v-model="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Confirm new password"
                  type="password"
                  autocomplete="current-password"
                  :class="{ 'border-red-600': submitted && v$.confirmNewPassword.$error }"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                />
              </div>
              <div
                v-for="(error, index) of v$.confirmNewPassword.$errors"
                :key="index"
                class="input-errors"
              >
                <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
              </div>
            </div>

            <button
              type="submit"
              class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
            >
              Save
            </button>
            <button
              type="submit"
              class="flex w-full justify-center rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
              @click="
                router.push({
                  name: 'menu',
                  params: { menu_slug: restoStore.MENU_URL }
                })
              "
            >
              Back
            </button>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
