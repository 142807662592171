import { defineStore } from 'pinia'
import { getCustomerInfoFn, customerLoginFn, customerLogoutFn } from '@/services/api/restApiCalls'

export const useAuthGlobalStore = defineStore('auth', {
  state: () => ({
    user: {},
    isLoggedIn: false,
    returnUrl: null
  }),
  persist: true,
  actions: {
    async login(email, password) {
      let loginFormData = new FormData()
      loginFormData.append('email', email)
      loginFormData.append('password', password)
      // await restApi
      //   .post('/web/customer/login', loginFormData)
      //   .then((res) => {
      //     let user_data = {}
      //     if (res?.status === 200) {
      //       user_data = res?.data
      //     }
      //     this.setAuthUser(user_data)
      //     return res?.data
      //   })
      //   .catch(() => {
      //     this.user = {}
      //     this.isLoggedIn = false
      //   })
      await customerLoginFn(loginFormData)
        .then((user_data) => {
          this.setAuthUser(user_data || {})
        })
        .catch(() => {
          this.user = {}
          this.isLoggedIn = false
        })
      return this.isLoggedIn
    },
    async logout() {
      // make api call to logout user here
      // let user = await restApi
      //   .post('/web/customer/logout')
      //   .then((res) => {
      //     let user_data = {}
      //     if (res?.status === 200) {
      //       user_data = res?.data
      //     }
      //     return user_data
      //   })
      //   .catch((error) => {
      //     return {}
      //   })
      let user = await customerLogoutFn()
      this.setAuthUser(user)
    },

    async getAuthUser() {
      // make api call to get auth user here
      let user = await getCustomerInfoFn()
      return user
    },

    setAuthUser(user) {
      // this is setter method to set auth user
      if (user.email && user.first_name) {
        this.user = { first_name: user.first_name, last_name: user.last_name }
        this.isLoggedIn = true
      } else {
        this.user = {}
        this.isLoggedIn = false
      }
    }
  }
})
