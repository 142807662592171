<script setup>
import { inject, onBeforeMount } from 'vue'
const IS_APP_DYNAMIC = inject('IS_APP_DYNAMIC')
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
import { useRouter } from 'vue-router'
const router = useRouter()
// Redirect to dynamic site if app is dynamic view and to static home url outside of vue if not
onBeforeMount(() => {
  if (IS_APP_DYNAMIC) {
    if (IS_MOBILE_APP) {
      return router.push({ name: 'mobileAppView' })
    } else {
      return router.push({ name: 'dynamicSite' })
    }
  } else {
    window.location.href = '/'
  }
})
</script>
<template>&nbsp;</template>
