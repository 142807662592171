<script setup>
import Voucher from '@/components/cart/cartTotal/voucher/Voucher.vue'
import PostCode from '@/components/cart/postCode/PostCode.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()

// const handleOrderCheckOut = () => {
//   if (authStore.isLoggedIn) {
//     router.push({ name: 'order_options' })
//   } else {
//     // router.push({ name: 'login' })
//     router.push({ name: 'login', query: { redirect: '/resto/order_options' } })
//   }
// }
</script>
<template>
  <div
    v-if="cartStore?.CART_DATA?.promo_spend_discount_text"
    class="bg-red-500 text-white rounded-sm text-sm rounded-sm font-medium px-2 py-1 whitespace-nowrap mb-3"
  >
    {{ cartStore?.CART_DATA?.promo_spend_discount_text }}
  </div>
  <div class="text-sm lg:text-md flex justify-between font-bold mb-2 text-gray-900">
    <span>Subtotal</span>
    <span>£{{ cartStore?.CART_DATA?.order_subtotal || 0.0 }}</span>
  </div>
  <div
    v-if="cartStore.CART_DATA?.order_offer_discount"
    class="text-sm flex justify-between mb-2 text-gray-900"
  >
    <span>Offer Discount</span>
    <span class="block text-red-700 font-semibold"
      >£-{{ cartStore?.CART_DATA?.order_offer_discount || 0 }}</span
    >
  </div>
  <Voucher v-if="restoStore?.restoInfo?.is_voucher_available"></Voucher>
  <div class="text-sm flex justify-between mb-2 text-gray-900">
    <span>Service Fee</span>
    <span>£{{ cartStore?.CART_DATA?.service_charge_fee || 0 }}</span>
  </div>
  <div
    v-if="cartStore.CART_DATA?.order_type == 'delivery'"
    class="text-sm flex justify-between mb-2 text-gray-900"
  >
    <span>Delivery Fee</span>
    <span>£{{ cartStore?.CART_DATA?.delivery_charge || 0 }}</span>
  </div>
  <PostCode></PostCode>
  <hr class="w-full my-2" />
  <div class="text-sm lg:text-md flex justify-between mb-2 font-bold text-gray-900">
    <span>Total</span>
    <span>£{{ cartStore?.CART_DATA?.order_total || 0 }}</span>
  </div>
  <!-- <div class="md:sticky bottom-0 w-full z-10 mx-auto bg-white">
    <button
      type="submit"
      :disabled="
        cartStore?.CAN_DO_CHECKOUT == 0 ||
        (cartStore?.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid)
      "
      class="w-full flex justify-between rounded-md border border-transparent bg-violet-700 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
      @click="handleOrderCheckOut()"
    >
      <span>{{ cartStore?.CART_DATA?.order_total_item_quantity || 0 }}</span>
      <span>Go To Checkout</span>
      <span>£{{ cartStore?.CART_DATA?.order_total || 0 }}</span>
    </button>
  </div> -->
</template>
