<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'

export default {
  components: {
    AppLayout
  },
  setup() {
    const restoGlobalStore = useRestoGLobalStore()
    const authStore = useAuthGlobalStore()
    const router = useRouter()
    // const email = ref('rohitdeshmukh69@gmail.com')
    // const password = ref('liverpool')
    const userEmail = ref('')
    const password = ref('')
    const errorMsg = ref('')

    const validationRules = {
      userEmail: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5)
      }
    }

    const v$ = useVuelidate(validationRules, {
      userEmail,
      password
    })
    const submitted = ref(false)

    const handleLogin = async () => {
      // if (submitted.value) return
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      const routeParam = router.currentRoute.value.query.redirect
      const menuSlug = restoGlobalStore.restoInfo.menu_url
      authStore.login(userEmail.value, password.value).then((isLoggedIn) => {
        if (isLoggedIn) {
          if (routeParam) {
            router.push({
              path: routeParam
            })
          } else {
            router.push({ name: 'menu', params: { menu_slug: menuSlug } })
          }
        } else {
          // TODO: show error message
          errorMsg.value = 'Invalid email or password'
        }
      })
      submitted.value = false
    }
    return {
      restoGlobalStore,
      authStore,
      router,
      submitted,
      userEmail,
      password,
      v$,
      handleLogin,
      errorMsg
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0 dark:bg-gray-900"
    >
      <!-- Card -->
      <div class="w-full max-w-xl p-6 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <h2 class="text-2xl font-bold text-gray-900 dark:text-white text-center mb-8">
          Sign in to platform
        </h2>
        <div class="text-sm font-medium text-red-600 mb-3">{{ errorMsg }}</div>
        <form class="space-y-4" @submit.prevent="handleLogin">
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Your email</label
            >
            <input
              type="email"
              name="email"
              id="email"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
              :class="{ 'border-red-600': submitted && v$.userEmail.$error }"
              placeholder="name@company.com"
              v-model="userEmail"
            />
            <div v-for="(error, index) of v$.userEmail.$errors" :key="index" class="input-errors">
              <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Your password</label
            >
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
              :class="{ 'border-red-600': submitted && v$.password.$error }"
              v-model="password"
            />
            <div v-for="(error, index) of v$.password.$errors" :key="index" class="input-errors">
              <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
            </div>
          </div>
          <div class="flex items-start justify-between">
            <div class="flex items-center h-5">
              <input
                id="remember"
                aria-describedby="remember"
                name="remember"
                type="checkbox"
                class="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              />
              <label for="remember" class="font-medium text-gray-900 text-sm ml-2 dark:text-white"
                >Remember me</label
              >
            </div>
            <div class="ml-3 text-sm">
              <router-link
                class="font-medium text-violet-700 text-sm dark:text-white hover:text-violet-800"
                :to="{ name: 'forgotPassword' }"
                >Lost Password?</router-link
              >
            </div>
          </div>
          <button
            type="submit"
            class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
          >
            Login to your account
          </button>
          <!-- <div class="relative mt-10">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <span class="bg-white px-6 text-gray-900">Or Login with</span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-2 gap-4">
            <a
              href="#"
              class="flex w-full items-center justify-center gap-3 rounded-md bg-[#385596] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.0"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 50 50"
                style="null"
                fill="currentColor"
                class="h-5 w-5"
              >
                <path
                  d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"
                ></path>
              </svg>
              <span class="text-sm font-semibold leading-6">Facebook</span>
            </a>

            <a
              href="#"
              class="flex w-full items-center justify-center gap-3 rounded-md bg-white border border-gray-500 px-3 py-1.5 text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 48 48"
                fill="currentColor"
                class="h-5 w-5"
              >
                <path
                  fill="#FFC107"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
                <path
                  fill="#FF3D00"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                ></path>
                <path
                  fill="#4CAF50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                ></path>
                <path
                  fill="#1976D2"
                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
              </svg>
              <span class="text-sm font-semibold leading-6">Google</span>
            </a>
          </div> -->
          <div class="text-sm font-medium text-gray-500 dark:text-gray-400">
            Not registered?
            <router-link
              class="font-medium text-violet-700 text-base dark:text-white hover:text-violet-800"
              :to="{ name: 'signup' }"
              >Create account</router-link
            >
            <!-- <a class="text-primary-700 hover:underline dark:text-primary-500">Create account</a> -->
          </div>
        </form>
      </div>
    </div>
  </AppLayout>
</template>
