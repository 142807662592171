import { defineStore } from 'pinia'
import { getRestoWebConfigFn, getRestoMenuFn } from '@/services/api/restApiCalls'

export const useRestoGLobalStore = defineStore('restoGlobalStore', {
  state: () => ({
    restoInfo: {},
    RESTO_ID: '',
    MENU_URL: '',
    MENU_DATA: {},
    MENU_FETCHED_TIMESTAMP: 0,
    OPEN_MODAL: false,
    CUSTOMER_INFO: {}
  }),
  actions: {
    async handleCheckRestoId() {
      const resto_info = await getRestoWebConfigFn()
      if (resto_info?.resto_id) {
        this.restoInfo = resto_info
        this.RESTO_ID = resto_info?.resto_id
        this.MENU_URL = resto_info?.menu_url
        // fetch resto menu
        this.fetchRestoMenu()
      } else {
        this.restoInfo = {}
        this.RESTO_ID = ''
        this.MENU_URL = ''
        this.MENU_DATA = {}
      }
    },
    async fetchRestoMenu() {
      if (this.RESTO_ID) {
        const menu_data = await getRestoMenuFn(this.RESTO_ID)
        this.MENU_DATA = menu_data
        this.MENU_FETCHED_TIMESTAMP = new Date().getTime()
      }
    },
    async checkAndRefetchRestoMenu() {
      if (this.RESTO_ID && this.MENU_URL && this.MENU_DATA) {
        const current_timestamp = new Date().getTime()
        // refetch menu if current timestamp is more than 10 mins from last fetched timestamp
        if (current_timestamp - this.MENU_FETCHED_TIMESTAMP > 1000 * 60 * 10) {
          this.fetchRestoMenu()
        }
      } else {
        this.fetchRestoMenu()
      }
    },
    showModal() {
      this.OPEN_MODAL = true
    },
    hideModal() {
      this.OPEN_MODAL = false
    }
  }
})
