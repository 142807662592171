<script>
import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { contactFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'

export default {
  components: {
    CheckCircleIcon
  },
  props: ['bgcolor'],
  setup(props) {
    // const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const name = ref('')
    const emailId = ref('')
    const subject = ref('')
    const message = ref('')
    const submitted = ref(false)
    const contactInfo = ref({})
    const btnBgColor = ref(props.bgcolor)

    const validationRules = computed(() => {
      let rules = {
        name: { required },
        emailId: {
          required,
          email
        },
        subject: { required },
        message: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      name,
      emailId,
      subject,
      message
    })
    const handleContactForm = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      const currentDate = new Date().toISOString().split('T')[0] //'YYYY-MM-DD' format
      const contactUsCaptcha = `beluga-contact-${currentDate}`
      const base64EncodedValue = btoa(contactUsCaptcha) //Encoded signupCaptcha
      const contactFormData = new FormData()
      contactFormData.append('name', name.value)
      contactFormData.append('email', emailId.value)
      contactFormData.append('subject', subject.value)
      contactFormData.append('message', message.value)
      contactFormData.append('beluga_captcha', base64EncodedValue)
      const contact_info = await contactFn(restoStore?.RESTO_ID, contactFormData)
      contactInfo.value = contact_info
    }

    return {
      name,
      emailId,
      subject,
      message,
      submitted,
      contactInfo,
      v$,
      router,
      restoStore,
      btnBgColor,
      handleContactForm
    }
  }
}
</script>
<template>
  <div class="flex flex-col items-center justify-center mx-auto dark:bg-gray-900">
    <div class="w-full p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
      <div v-if="contactInfo?.status == 200" class="text-center">
        <CheckCircleIcon class="h-14 w-14 text-green-500 mx-auto" aria-hidden="true" />
        <h3 class="text-xl font-semibold text-gray-700 my-1">Thank you for getting in touch!</h3>
        <!-- <div class="mt-6">
          <button
            type="button"
            class="rounded-md border border-transparent bg-gray-400 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
            @click="
              router.push({
                name: 'menu',
                params: { menu_slug: restoStore.MENU_URL }
              })
            "
          >
            Back
          </button>
        </div> -->
      </div>
      <template v-else>
        <div class="sm:mx-auto sm:w-full sm:max-w-4xl">
          <div class="">
            <form class="space-y-4" @submit.prevent="handleContactForm">
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                  <div class="mt-1 text-gray-800">
                    <input
                      id="name"
                      v-model="name"
                      name="name"
                      type="text"
                      autocomplete="name"
                      placeholder="Enter name"
                      :class="{ 'border-red-600': submitted && v$.name.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div v-for="(error, index) of v$.name.$errors" :key="index" class="input-errors">
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="email" class="block text-sm font-medium text-gray-700"
                    >Email address</label
                  >
                  <div class="mt-1 text-gray-800">
                    <input
                      id="email"
                      v-model="emailId"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Enter email"
                      :class="{ 'border-red-600': submitted && v$.emailId.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.emailId.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="subject" class="block text-sm font-medium text-gray-700"
                    >Subject</label
                  >
                  <div class="mt-1 text-gray-800">
                    <input
                      id="subject"
                      v-model="subject"
                      name="subject"
                      type="text"
                      autocomplete="subject"
                      placeholder="Enter subject"
                      :class="{ 'border-red-600': submitted && v$.subject.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.subject.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="message" class="block text-sm font-medium text-gray-700"
                    >Message</label
                  >
                  <div class="mt-1 text-gray-800">
                    <textarea
                      id="message"
                      v-model="message"
                      name="message"
                      autocomplete="message"
                      placeholder="Write something here..."
                      :class="{ 'border-red-600': submitted && v$.message.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    ></textarea>
                  </div>
                  <div
                    v-for="(error, index) of v$.message.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="flex justify-center">
                <button
                  type="submit"
                  class="w-2/4 justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm bg-violet-800 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                  :style="{ background: `${btnBgColor}` }"
                >
                  Submit
                </button>
                <!-- <button
                  type="button"
                  class="w-2/4 ml-2 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                  @click="
                    router.push({
                      name: 'menu',
                      params: { menu_slug: restoStore.MENU_URL }
                    })
                  "
                >
                  Back
                </button> -->
              </div>
            </form>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
