<script setup>
import { ref, onBeforeMount } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import OrderItems from '@/views/payment/common/OrderItems.vue'
import DeliveryOrder from '@/views/payment/common/DeliveryOrder.vue'
import CollectionOrder from '@/views/payment/common/CollectionOrder.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { getCartInfoFn } from '@/services/api/restApiCalls'

const cartStore = useCartGLobalStore()
const restoStore = useRestoGLobalStore()
const dataLoaded = ref(false)

onBeforeMount(async () => {
  const cart_info = await getCartInfoFn(restoStore?.RESTO_ID)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  restoStore.CUSTOMER_INFO = cart_info.customer_info
  dataLoaded.value = true
})
</script>
<template>
  <AppLayout>
    <div class="container mx-auto md:pt-12 pt-8 pb-5">
      <div class="sm:flex sm:items-center mb-2">
        <div class="sm:flex-auto text-center">
          <h1 class="md:text-2xl text-xl font-semibold text-gray-900 mb-3">
            Placing
            <span
              class="capitalize font-bold italic"
              :class="[
                cartStore?.CART_DATA?.order_type == 'delivery'
                  ? 'text-color-delivery'
                  : 'text-color-collection'
              ]"
              >{{ cartStore?.CART_DATA?.order_type }}</span
            >
            order of
            <span
              class="capitalize font-bold italic text-color-delivery"
              :class="[
                cartStore?.CART_DATA?.order_type == 'delivery'
                  ? 'text-color-delivery'
                  : 'text-color-collection'
              ]"
              >£{{ cartStore?.CART_DATA?.order_total }}</span
            >
          </h1>
        </div>
      </div>
      <div class="mt-2">
        <div class="md:px-6 sm:px-4 px-2 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-0">
          <div
            class="lg:col-span-6 lg:mt-0 bg-white border border-gray-200 rounded-sm sm:p-6 p-4 md:mb-0 mb-4"
          >
            <div class="space-y-4">
              <!-- <template v-if="cartStore?.CART_DATA?.order_type == 'delivery'">
              <div>
                <label for="phoneNumber" class="block text-sm font-medium text-gray-900"
                  >Phone Number</label
                >
                <div class="mt-1">
                  <input
                    id="phoneNumber"
                    v-model="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    autocomplete="phoneNumber"
                    :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.phoneNumber.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">Phone Number is required</div>
                </div>
              </div>
              <div>
                <label for="addressLine1" class="block text-sm font-medium text-gray-900"
                  >Delivery Address Line 1</label
                >
                <div class="mt-1">
                  <input
                    id="addressLine1"
                    v-model="addressLine1"
                    name="addressLine1"
                    type="text"
                    autocomplete="addressLine1"
                    :class="{ 'border-red-600': submitted && v$.addressLine1.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.addressLine1.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">
                    Delivery Address Line 1 is required
                  </div>
                </div>
              </div>
              <div>
                <label for="addressLine2" class="block text-sm font-medium text-gray-900"
                  >Delivery Address Line 2</label
                >
                <div class="mt-1">
                  <input
                    id="addressLine2"
                    v-model="addressLine2"
                    name="addressLine2"
                    type="text"
                    autocomplete="addressLine2"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
              </div>
              <div>
                <label for="city" class="block text-sm font-medium text-gray-900">City</label>
                <div class="mt-1">
                  <input
                    id="city"
                    v-model="city"
                    name="city"
                    type="text"
                    autocomplete="city"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
              </div>
              <div>
                <label for="postCode" class="block text-sm font-medium text-gray-900"
                  >Post code</label
                >
                <div class="mt-1">
                  <input
                    id="postCode"
                    v-model="postCode"
                    name="postCode"
                    type="text"
                    autocomplete="postCode"
                    :class="{ 'border-red-600': submitted && v$.postCode.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.postCode.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">Post code is required</div>
                </div>
                <div class="text-sm font-medium text-red-600 capitalize">{{ postCodeError }}</div>
              </div>
              <div>
                <label for="comment" class="block text-sm font-medium text-gray-900"
                  >Note For Restaurant</label
                >
                <div class="mt-1">
                  <textarea
                    id="comment"
                    v-model="noteForResto"
                    rows="4"
                    name="comment"
                    placeholder="Add instruction about food or mention any allergy"
                    class="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  ></textarea>
                </div>
              </div>
            </template>
            <template v-if="cartStore?.CART_DATA?.order_type == 'collection'">
              <div>
                <label for="phoneNumber" class="block text-sm font-medium text-gray-900"
                  >Phone Number</label
                >
                <div class="mt-1">
                  <input
                    id="phoneNumber"
                    v-model="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    autocomplete="phoneNumber"
                    :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.phoneNumber.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">Phone Number is required</div>
                </div>
              </div>
              <div>
                <label for="comment" class="block text-sm font-medium text-gray-900"
                  >Note For Restaurant</label
                >
                <div class="mt-1">
                  <textarea
                    id="comment"
                    v-model="noteForResto"
                    rows="4"
                    name="comment"
                    placeholder="Add instruction about food or mention any allergy"
                    class="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  ></textarea>
                </div>
              </div>
            </template> -->
              <template v-if="cartStore.CART_DATA?.order_type == 'delivery'">
                <DeliveryOrder v-if="dataLoaded"></DeliveryOrder>
              </template>
              <template v-if="cartStore.CART_DATA?.order_type == 'collection'">
                <CollectionOrder v-if="dataLoaded"></CollectionOrder>
              </template>
            </div>
          </div>
          <OrderItems></OrderItems>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
