<script setup>
import { onMounted } from 'vue'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
const authStore = useAuthGlobalStore()
import { useRouter } from 'vue-router'
const router = useRouter()
const handleLogout = async () => {
  authStore.logout().then(() => {
    router.push({ name: 'restoHome' })
  })
}
onMounted(() => {
  handleLogout()
})
</script>
<template>&nbsp;</template>
