<script setup>
import { defineProps, ref, watch } from 'vue'
import {
  XMarkIcon,
  ChevronLeftIcon,
  ListBulletIcon,
  LifebuoyIcon,
  PencilIcon
} from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import BillSplitEqually from '@/components/posbill/billOption/BillSplitEqually.vue'
import PayForItems from '@/components/posbill/billOption/PayForItems.vue'
import PayCustom from '@/components/posbill/billOption/PayCustom.vue'
import PosBillPayment from '@/components/posbill/cardPayment/PosBillPayment.vue'

const props = defineProps(['show'])

const showPayBill = ref(true)
const showSplitBill = ref(false)
const showBillSplitEqually = ref(false)
const showPayForItems = ref(false)
const showPayCustom = ref(false)
const showCardPayment = ref(false)

const handleSplitBill = () => {
  showSplitBill.value = true
  showPayBill.value = false
}
const handleDivideBill = () => {
  showBillSplitEqually.value = true
  showSplitBill.value = false
  showPayBill.value = false
}
const handlePayForItems = () => {
  showPayForItems.value = true
  showSplitBill.value = false
  showPayBill.value = false
}
const handlePayCustom = () => {
  showPayCustom.value = true
  showSplitBill.value = false
  showPayBill.value = false
}
const handleCardPayment = () => {
  showCardPayment.value = true
  showSplitBill.value = false
  showBillSplitEqually.value = false
  showPayForItems.value = false
  showPayCustom.value = false
  showPayBill.value = false
}
const handleBackButton = () => {
  showBillSplitEqually.value = false
  showPayForItems.value = false
  showPayCustom.value = false
  showCardPayment.value = false
  showSplitBill.value = true
}
const handleBackToPay = () => {
  showSplitBill.value = false
  showPayBill.value = true
}
watch(
  () => props.show,
  () => {
    showSplitBill.value = false
    showBillSplitEqually.value = false
    showPayForItems.value = false
    showPayCustom.value = false
    showCardPayment.value = false
    showPayBill.value = true
  }
)
</script>
<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-40" @click="$emit('closePayBillModal')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-20 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-end sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-t-xl bg-white px-5 pt-6 pb-5 text-left shadow-xl transition-all mt-8 w-full max-w-xl sm:p-6"
            >
              <div class="absolute top-0 right-0 pt-2 pr-2 block">
                <button
                  type="button"
                  class="rounded-full border border-e-violet p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
                  @click="$emit('closePayBillModal')"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div class="overflow-auto">
                <div v-if="showPayBill" class="">
                  <h3 class="text-2xl font-semibold text-gray-900 mb-5">Pay your bill</h3>
                  <button
                    class="w-full text-center bg-black text-white shadow-sm px-4 py-4 text-xl font-semibold rounded-full mt-4"
                    @click="handleSplitBill()"
                  >
                    <span class="mr-auto"> Split the bill</span>
                  </button>
                  <button
                    class="w-full text-center bg-black text-white shadow-sm px-4 py-4 text-xl font-semibold rounded-full mt-4"
                    @click="handleCardPayment()"
                  >
                    <span class="mr-auto"> Pay full bill</span>
                  </button>
                </div>
                <div v-if="showSplitBill" class="">
                  <div class="flex items-baseline">
                    <button
                      type="button"
                      class="mr-8 p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
                      @click.stop="handleBackToPay()"
                    >
                      <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                    <h3 class="text-2xl font-semibold text-gray-900 mb-5">Split the bill</h3>
                  </div>

                  <button
                    class="w-full flex items-center justify-center text-center bg-black text-white shadow-sm px-8 py-4 text-xl font-semibold rounded-full mt-4"
                    @click="handlePayForItems()"
                  >
                    <ListBulletIcon class="h-8 w-8 mr-auto" aria-hidden="true" />
                    <span class="mr-auto"> Pay for your items</span>
                  </button>
                  <button
                    class="w-full flex items-center justify-center text-center bg-black text-white shadow-sm px-8 py-4 text-xl font-semibold rounded-full mt-4"
                    @click="handleDivideBill()"
                  >
                    <LifebuoyIcon class="h-8 w-8 mr-auto" aria-hidden="true" />
                    <span class="mr-auto"> Divide the bill equally</span>
                  </button>
                  <button
                    class="w-full flex items-center justify-center text-center bg-black text-white shadow-sm px-8 py-4 text-xl font-semibold rounded-full mt-4"
                    @click="handlePayCustom()"
                  >
                    <PencilIcon class="h-8 w-8 mr-auto" aria-hidden="true" />
                    <span class="mr-auto"> Pay custom amount</span>
                  </button>
                </div>
                <BillSplitEqually
                  v-if="showBillSplitEqually"
                  @handleBackToSplit="handleBackButton()"
                ></BillSplitEqually>
                <PayForItems
                  v-if="showPayForItems"
                  @handleBackToSplit="handleBackButton()"
                ></PayForItems>
                <PayCustom v-if="showPayCustom" @handleBackToSplit="handleBackButton()"></PayCustom>
                <PosBillPayment
                  v-if="showCardPayment"
                  @handleBackToSplit="handleBackButton()"
                ></PosBillPayment>
                <div
                  v-if="showBillSplitEqually || showPayForItems || showPayCustom"
                  class="text-center mt-4"
                >
                  <button
                    class="w-full text-center bg-black text-white shadow-sm px-4 py-4 text-xl font-semibold rounded-full mt-4"
                    @click="handleCardPayment()"
                  >
                    <span class="mr-auto"> Confirm</span>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
