<script setup>
import { ref } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { ShoppingBagIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { updateOrderTypeFn } from '@/services/api/restApiCalls'
const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const selectedOrderType = ref(cartStore?.CART_DATA?.order_type)

const handleMenuChange = async () => {
  let OrderTypeFormData = new FormData()
  OrderTypeFormData.append('resto_id', restoStore?.RESTO_ID)
  OrderTypeFormData.append('order_type', selectedOrderType.value)
  const order_type = await updateOrderTypeFn(OrderTypeFormData)
  cartStore.CART_DATA = order_type[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = order_type?.can_do_checkout
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
  }
}
// onMounted(() => {
//   const cartStore = useCartGLobalStore()
//   console.log('Mounted Cart store', cartStore?.CART_DATA?.order_type)
//   selectedOrderType.value = cartStore?.CART_DATA?.order_type
// })
</script>

<template>
  <div class="bg-gray-200 flex items-center px-1 py-1 md:mb-2 mb-2 rounded-full w-full shadow">
    <button
      class="w-1/2 relative cursor-pointer text-base flex justify-center items-center sm:px-1 px-0 py-1 text-gray-800 rounded-full border-none focus:outline-none transition duration-200 ease-in-out disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-slate-400"
      :class="[
        cartStore.CART_DATA?.order_type == 'collection' ? 'bg-violet-600 text-white' : 'bg-white',
        !restoStore?.restoInfo?.is_collection_available ? 'line-through' : ''
      ]"
      :disabled="!restoStore?.restoInfo?.is_collection_available"
      @click="handleMenuChange((selectedOrderType = 'collection'))"
    >
      <ShoppingBagIcon class="md:h-5 h-4 md:w-5 w-4 mr-1" aria-hidden="true" />
      <span class="lg:text-base text-sm">Collection</span>
      <XMarkIcon
        v-if="!restoStore?.restoInfo?.is_collection_available"
        class="h-7 w-7 text-red-400 absolute"
        aria-hidden="true"
      />
    </button>
    <button
      class="w-1/2 relative cursor-pointer text-base flex justify-center items-center sm:px-1 px-0 py-1 rounded-full border-none focus:outline-none transition duration-200 ease-in-out disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-slate-400"
      :class="[
        cartStore.CART_DATA?.order_type == 'delivery' ? 'bg-green-600 text-white' : 'bg-white',
        !restoStore?.restoInfo?.is_delivery_available ? 'line-through' : ''
      ]"
      :disabled="!restoStore?.restoInfo?.is_delivery_available"
      @click="handleMenuChange((selectedOrderType = 'delivery'))"
    >
      <svg
        class="sm:h-5 h-4 sm:w-5 w-4 fill-current mr-1"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
        ></path>
      </svg>
      <span class="lg:text-base text-sm">Delivery </span>
      <!-- <span class="border border-red-400 absolute w-1/3"> </span> -->
      <XMarkIcon
        v-if="!restoStore?.restoInfo?.is_delivery_available"
        class="h-7 w-7 text-red-400 absolute"
        aria-hidden="true"
      />
    </button>
  </div>
</template>
