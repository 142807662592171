<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'

const restoStore = useRestoGLobalStore()
</script>
<template>
  <div
    v-if="restoStore?.restoInfo?.active_website_offers.length > 0"
    class="md:p-3 p-2 bg-red-500 text-white md:text-lg text-base md:col-span-6 text-center"
  >
    <div v-for="(offer, index) in restoStore?.restoInfo?.active_website_offers" :key="index">
      {{ offer }}
    </div>
  </div>
  <div class="text-sm md:p-3 p-2 bg-white mb-3">
    If you suffer from an allergy that could endanger your health we strongly advise that you
    contact us before placing your order. Please refer to the contact us page for our contact
    details.
  </div>
</template>
