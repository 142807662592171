import { defineStore } from 'pinia'
import { getCartInfoFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'

export const useCartGLobalStore = defineStore('cartGlobalStore', {
  state: () => ({
    CART_DATA: {},
    ORDER_TYPE: '',
    CAN_DO_CHECKOUT: 0,
    SHOW_POSTCODE_MODAL: false,
    SHOW_ITEM_ADDED_ALERT: false,
    RESTO_STATUS: {}
  }),
  actions: {
    async fetchCartInfo() {
      const restoStore = useRestoGLobalStore()
      const cart_info = await getCartInfoFn(restoStore?.RESTO_ID)
      this.CART_DATA = cart_info[restoStore?.RESTO_ID]
      this.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
      this.RESTO_STATUS = cart_info?.resto_live_status
    }
  }
})
