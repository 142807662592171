<script>
import { ref, onBeforeMount, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import {
  getRestoPaymentSettingFn,
  createOrderPIFn,
  placeCardOrderSCAFn,
  confirmOrderPIFn
} from '@/services/api/restApiCalls'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

export default {
  components: {
    StripeElements,
    StripeElement
  },

  setup() {
    const router = useRouter()
    const restoStore = useRestoGLobalStore()
    const cartStore = useCartGLobalStore()

    let stripeKey = ref('')
    let stripeAccountID = ref('')
    const stripeLoaded = ref(false)
    const cardErrorMsg = ref('')
    const card = ref()
    const elms = ref()
    const payButtonDisabled = ref(false)
    let is_card_processing = ref(false)
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    })
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    })
    const style = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
      // CardField: {
      //   padding: '5px',
      //   border: '1px solid gray'
      // }
    }
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      style: style,
      hidePostalCode: true
    })

    // onMounted(async () => {
    //   let payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
    //   is_card_processing.value = false
    //   // retry to get payment settings
    //   if (!payment_settings?.public_key) {
    //     payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
    //   }

    //   if (payment_settings?.public_key) {
    //     stripeKey.value = payment_settings?.public_key
    //   }
    //   if (payment_settings?.account_id) {
    //     stripeAccountID.value = payment_settings?.account_id
    //     instanceOptions.value.stripeAccount = stripeAccountID.value
    //   }
    // })

    const handleStripePayment = async () => {
      if (is_card_processing.value) {
        return
      }
      is_card_processing.value = true
      let cardElement = card.value.stripeElement
      elms.value.instance
        .createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        .then(async function (result) {
          // Handle result.error or result.paymentMethod
          if (result?.error) {
            cardErrorMsg.value = result?.error?.message
            payButtonDisabled.value = true
          } else {
            let paymentMethodId = result?.paymentMethod?.id

            const place_order = await placeCardOrderSCAFn(restoStore?.RESTO_ID, paymentMethodId)
            let orderStatus = place_order?.order_status
            if (orderStatus == 'REQUIRES_ACTION') {
              let orderId = place_order?.order_id
              let client_secret = place_order?.client_secret
              elms.value.instance.handleCardAction(client_secret).then(async function () {
                const confirm_pi_data = await confirmOrderPIFn(restoStore?.RESTO_ID, orderId)
                if (confirm_pi_data?.status == 200) {
                  let orderId = confirm_pi_data?.order_id
                  is_card_processing.value = false
                  router.push({
                    name: 'order_status',
                    params: { order_id: `${orderId}` }
                  })
                  cartStore.CART_DATA = ''
                }
              })
            } else {
              is_card_processing.value = false
              router.push({
                name: 'order_status',
                params: { order_id: `${place_order?.order_id}` }
              })
              cartStore.CART_DATA = ''
            }
          }
          is_card_processing.value = false
        })
    }

    onBeforeMount(async () => {
      is_card_processing.value = false

      const create_pi_data = await createOrderPIFn(restoStore?.RESTO_ID)

      let payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)

      // retry to get payment settings
      if (!payment_settings?.public_key) {
        payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
      }

      if (payment_settings?.public_key) {
        stripeKey.value = payment_settings?.public_key
      }
      if (payment_settings?.account_id) {
        stripeAccountID.value = payment_settings?.account_id
        instanceOptions.value.stripeAccount = stripeAccountID.value
      }

      if (stripeKey.value) {
        const stripePromise = loadStripe(stripeKey.value)
        stripePromise.then(() => {
          stripeLoaded.value = true
        })
      } else {
        router.push({
          name: 'menu',
          params: { menu_slug: restoStore?.MENU_URL }
        })
      }
    })

    const handleCardChange = (event) => {
      payButtonDisabled.value = event.empty
      let error_msg = event.error ? event.error.message : ''
      cardErrorMsg.value = error_msg
      if (event.brand && event.brand === 'amex') {
        cardErrorMsg.value = 'Amex card is not supported'
        payButtonDisabled.value = true
      }
    }
    return {
      restoStore,
      cartStore,
      stripeLoaded,
      stripeKey,
      stripeAccountID,
      cardErrorMsg,
      card,
      elms,
      instanceOptions,
      elementsOptions,
      cardOptions,
      payButtonDisabled,
      handleStripePayment,
      handleCardChange
    }
  }
}
</script>
<template>
  <StripeElements
    v-if="stripeLoaded"
    v-slot="{ elements, instance }"
    ref="elms"
    :stripe-key="stripeKey"
    :instance-options="instanceOptions"
    :elements-options="elementsOptions"
  >
    <StripeElement
      ref="card"
      :elements="elements"
      :options="cardOptions"
      @change="handleCardChange($event)"
    ></StripeElement>
  </StripeElements>
  <div class="text-sm font-medium text-red-600 lblError">{{ cardErrorMsg }}</div>
</template>
