<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, computed, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { customerReservationFn, getCustomerInfoFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRouter } from 'vue-router'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    LoadingSpinner
  },
  setup() {
    const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const urlRestoId = parseInt(router.currentRoute.value.params.resto_id)
    // Check if URL resto_id is different from store resto_id
    if (urlRestoId !== restoStore?.RESTO_ID) {
      router.push({ path: `/resto/${restoStore?.RESTO_ID}/reservation` })
    }
    // Check if reservation active
    const isReservationActiveValue = restoStore?.restoInfo?.is_reservation_active
    if (!isReservationActiveValue) {
      router.push('/')
    }
    const name = ref('')
    const emailId = ref('')
    const phoneNumber = ref('')
    // const guests = ref('')
    const date = ref('')
    const selectedTime = ref('19:00')
    const time_option = ref([
      { id: 1, time: '10:15 am', value: '10:15' },
      { id: 2, time: '10:30 am', value: '10:30' },
      { id: 3, time: '10:45 am', value: '10:45' },
      { id: 4, time: '11:00 am', value: '11:00' },
      { id: 5, time: '11:15 am', value: '11:15' },
      { id: 6, time: '11:30 am', value: '11:30' },
      { id: 7, time: '11:45 am', value: '11:45' },
      { id: 8, time: '12:00 pm', value: '12:00' },
      { id: 9, time: '12:15 pm', value: '12:15' },
      { id: 10, time: '12:30 pm', value: '12:30' },
      { id: 11, time: '12:45 pm', value: '12:45' },
      { id: 12, time: '1:00 pm', value: '13:00' },
      { id: 13, time: '1:15 pm', value: '13:15' },
      { id: 14, time: '1:30 pm', value: '13:30' },
      { id: 15, time: '1:45 pm', value: '13:45' },
      { id: 16, time: '2:00 pm', value: '14:00' },
      { id: 17, time: '2:15 pm', value: '14:15' },
      { id: 18, time: '2:30 pm', value: '14:30' },
      { id: 19, time: '2:45 pm', value: '14:45' },
      { id: 20, time: '3:00 pm', value: '15:00' },
      { id: 21, time: '3:15 pm', value: '15:15' },
      { id: 22, time: '3:30 pm', value: '15:30' },
      { id: 23, time: '3:45 pm', value: '15:45' },
      { id: 24, time: '4:00 pm', value: '16:00' },
      { id: 25, time: '4:15 pm', value: '16:15' },
      { id: 26, time: '4:30 pm', value: '16:30' },
      { id: 27, time: '4:45 pm', value: '16:45' },
      { id: 28, time: '5:00 pm', value: '17:00' },
      { id: 29, time: '5:15 pm', value: '17:15' },
      { id: 30, time: '5:30 pm', value: '17:30' },
      { id: 31, time: '5:45 pm', value: '17:45' },
      { id: 32, time: '6:00 pm', value: '18:00' },
      { id: 33, time: '6:15 pm', value: '18:15' },
      { id: 34, time: '6:30 pm', value: '18:30' },
      { id: 35, time: '6:45 pm', value: '18:45' },
      { id: 36, time: '7:00 pm', value: '19:00' },
      { id: 37, time: '7:15 pm', value: '19:15' },
      { id: 38, time: '7:30 pm', value: '19:30' },
      { id: 39, time: '7:45 pm', value: '19:45' },
      { id: 40, time: '8:00 pm', value: '20:00' },
      { id: 41, time: '8:15 pm', value: '20:15' },
      { id: 42, time: '8:30 pm', value: '20:30' },
      { id: 43, time: '8:45 pm', value: '20:45' },
      { id: 44, time: '9:00 pm', value: '21:00' },
      { id: 45, time: '9:15 pm', value: '21:15' },
      { id: 46, time: '9:30 pm', value: '21:30' },
      { id: 47, time: '9:45 pm', value: '21:45' },
      { id: 48, time: '10:00 pm', value: '22:00' },
      { id: 49, time: '10:15 pm', value: '22:15' },
      { id: 50, time: '10:30 pm', value: '22:30' },
      { id: 51, time: '10:45 pm', value: '22:45' },
      { id: 52, time: '11:00 pm', value: '23:00' }
    ])
    const guests = ref([
      { id: 1, value: '1' },
      { id: 2, value: '2' },
      { id: 3, value: '3' },
      { id: 4, value: '4' },
      { id: 5, value: '5' },
      { id: 6, value: '6' },
      { id: 7, value: '7' },
      { id: 8, value: '8' },
      { id: 9, value: '9' },
      { id: 10, value: '10' },
      { id: 11, value: '11' },
      { id: 12, value: '12' },
      { id: 13, value: '13' },
      { id: 14, value: '14' },
      { id: 15, value: '15' }
    ])
    const selectedGuest = ref('2')
    const message = ref('')
    const submitted = ref(false)
    const reservationInfo = ref({})
    const reservationDateTime = ref('')
    let today = new Date()
    let maxDate = new Date(Date.now() + 1.555e10)
    const minReservationDate = ref(getDateFormat(today))
    const maxReservationDate = ref(getDateFormat(maxDate))
    const isLoading = ref(false)

    function getDateFormat(date) {
      let year = date.getFullYear()
      let month = String(date.getMonth() + 1).padStart(2, '0')
      let day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }
    const validationRules = computed(() => {
      let rules = {
        name: { required },
        emailId: {
          required,
          email
        },
        phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(15) },
        selectedGuest: { required },
        date: { required },
        selectedTime: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      name,
      emailId,
      phoneNumber,
      selectedGuest,
      date,
      selectedTime
    })
    const handleReservation = async () => {
      reservationInfo.value = ''
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      var reservationFormData = new FormData()
      reservationFormData.append('name', name.value)
      reservationFormData.append('email', emailId.value)
      reservationFormData.append('phone_number', phoneNumber.value)
      reservationFormData.append('guests', selectedGuest.value)
      reservationFormData.append('requested_datetime_str', date.value)
      reservationFormData.append('requested_time', selectedTime.value)
      reservationFormData.append('message', message.value)
      const reservation_info = await customerReservationFn(
        restoStore?.RESTO_ID,
        reservationFormData
      )
      reservationInfo.value = reservation_info
      isLoading.value = false
      if(reservationInfo?.value.status == 400){
        notify(
        {
          group: 'errormessage',
          title: 'Error',
          text: `${reservationInfo.value.status_msg}`
        },
        2000
      )
      }
      
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
      const convertToDate = new Date(
        reservation_info?.reservation?.requested_datetime
      ).toLocaleDateString('en-GB', options)
      reservationDateTime.value = convertToDate
    }
    const getCustomerInfo = async () => {
      const customer_info = await getCustomerInfoFn()
      name.value = customer_info?.first_name + ' ' + customer_info?.last_name
      emailId.value = customer_info?.email
      phoneNumber.value = customer_info?.phone_number
    }

    onMounted(() => {
      if (authStore.isLoggedIn) {
        getCustomerInfo()
      }
    })
    watch([name, emailId, phoneNumber, selectedTime, selectedGuest, date, message], () => {
      reservationInfo.value = ''
    })
    return {
      name,
      emailId,
      phoneNumber,
      guests,
      date,
      selectedTime,
      selectedGuest,
      message,
      time_option,
      submitted,
      v$,
      router,
      restoStore,
      handleReservation,
      reservationInfo,
      reservationDateTime,
      minReservationDate,
      maxReservationDate,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0 dark:bg-gray-900"
    >
      <div
        class="w-full max-w-2xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800"
      >
        <div v-if="reservationInfo?.status == 400" class="rounded-md bg-red-50 px-4 py-2">
          <div class="flex justify-center items-center">
            <div class="flex-shrink-0">
              <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-2">
              <p class="text-md font-medium text-red-800">{{ reservationInfo?.status_msg }}</p>
            </div>
          </div>
        </div>
        <div v-if="reservationInfo?.status == 201" class="text-center">
          <CheckCircleIcon class="h-14 w-14 text-green-500 mx-auto" aria-hidden="true" />
          <!-- <h3 class="text-xl font-semibold text-gray-700 my-1">Your Reservation Received</h3> -->
          <h3 class="text-xl font-semibold text-gray-700 my-1">
            Your Reservation has been Received!
          </h3>
          <div class="">
            <p class="text-md font-normal text-gray-700">
              Name: <span class="font-medium">{{ reservationInfo?.reservation?.name }}</span>
            </p>
            <p class="text-md font-normal text-gray-700">
              Time:
              <span class="font-medium">{{ reservationDateTime }}</span>
            </p>
          </div>
          <h3 class="text-lg font-semibold text-gray-700 my-1">Thank you for choosing us.</h3>
          <div class="mt-6">
            <button
              type="button"
              class="rounded-md border border-transparent bg-gray-400 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
              @click="
                router.push({
                  name: 'menu',
                  params: { menu_slug: restoStore.MENU_URL }
                })
              "
            >
              Back
            </button>
          </div>
        </div>
        <template v-else>
          <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <!-- <h1 class="mb-4 text-center text-xl font-semibold tracking-tight text-gray-900">Setting</h1> -->
            <h2 class="text-2xl font-bold text-gray-900 dark:text-white text-center">
              Reserve Table at {{ restoStore?.restoInfo?.name }}
            </h2>
          </div>

          <div class="sm:mx-auto sm:w-full sm:max-w-4xl">
            <div class="">
              <form class="space-y-4" @submit.prevent="handleReservation">
                <div class="md:grid md:grid-cols-12 md:gap-x-6">
                  <div class="md:col-span-12">
                    <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                    <div class="mt-1">
                      <input
                        id="name"
                        v-model="name"
                        name="name"
                        type="text"
                        autocomplete="name"
                        placeholder="Enter name"
                        :class="{ 'border-red-600': submitted && v$.name.$error }"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.name.$errors"
                      :key="index"
                      class="input-errors"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="md:grid md:grid-cols-12 md:gap-x-6">
                  <div class="md:col-span-12">
                    <label for="email" class="block text-sm font-medium text-gray-700"
                      >Email address</label
                    >
                    <div class="mt-1">
                      <input
                        id="email"
                        v-model="emailId"
                        name="email"
                        type="email"
                        autocomplete="email"
                        placeholder="Enter email"
                        :class="{ 'border-red-600': submitted && v$.emailId.$error }"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.emailId.$errors"
                      :key="index"
                      class="input-errors"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="md:grid md:grid-cols-12 md:gap-x-6">
                  <div class="md:col-span-12">
                    <label for="phoneNumber" class="block text-sm font-medium text-gray-700"
                      >Phone Number</label
                    >
                    <div class="mt-1">
                      <input
                        id="phoneNumber"
                        v-model="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        autocomplete="phoneNumber"
                        placeholder="Enter phone number"
                        :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.phoneNumber.$errors"
                      :key="index"
                      class="input-errors"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="md:grid md:grid-cols-12 md:gap-x-6">
                  <div class="md:col-span-12">
                    <label for="guests" class="block text-sm font-medium text-gray-700"
                      >No of Guests</label
                    >
                    <div class="mt-1">
                      <select
                        id="time"
                        name="time"
                        class="h-10 block w-full rounded-md border-0 px-3 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none sm:text-sm sm:leading-6"
                        :class="{ 'ring-red-600': submitted && v$.selectedGuest.$error }"
                        v-model="selectedGuest"
                      >
                        <option v-for="guest of guests" :key="guest.id" :value="guest.value">
                          {{ guest.value }}
                        </option>
                      </select>
                      <!-- <input
                        id="guests"
                        v-model="guests"
                        name="guests"
                        type="number"
                        min="1"
                        autocomplete="guests"
                        placeholder="Enter No of guests"
                        :class="{ 'border-red-600': submitted && v$.guests.$error }"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      /> -->
                    </div>
                    <div
                      v-for="(error, index) of v$.selectedGuest.$errors"
                      :key="index"
                      class="input-errors"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="md:grid md:grid-cols-12 md:gap-x-6">
                  <div class="md:col-span-6">
                    <label for="date" class="block text-sm font-medium text-gray-700"
                      >Select Date</label
                    >
                    <div class="mt-1">
                      <input
                        id="date"
                        v-model="date"
                        name="date"
                        type="date"
                        :min="minReservationDate"
                        :max="maxReservationDate"
                        autocomplete="date"
                        :class="{ 'border-red-600': submitted && v$.date.$error }"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.date.$errors"
                      :key="index"
                      class="input-errors"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="md:col-span-6">
                    <label for="time" class="block text-sm font-medium text-gray-700"
                      >Select Time</label
                    >
                    <div class="mt-1">
                      <select
                        id="time"
                        name="time"
                        class="h-10 block w-full rounded-md border-0 px-3 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none sm:text-sm sm:leading-6"
                        :class="{ 'ring-red-600': submitted && v$.selectedTime.$error }"
                        v-model="selectedTime"
                      >
                        <option
                          v-for="selectTime of time_option"
                          :key="selectTime.id"
                          :value="selectTime.value"
                        >
                          {{ selectTime.time }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-for="(error, index) of v$.selectedTime.$errors"
                      :key="index"
                      class="input-errors"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="md:grid md:grid-cols-12 md:gap-x-6">
                  <div class="md:col-span-12">
                    <label for="message" class="block text-sm font-medium text-gray-700"
                      >Message</label
                    >
                    <div class="mt-1">
                      <textarea
                        id="message"
                        v-model="message"
                        name="message"
                        autocomplete="message"
                        placeholder="Write something here..."
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div v-if="reservationInfo?.status == 400" class="rounded-md bg-red-50 px-4 py-2">
                  <div class="flex justify-center items-center">
                    <div class="flex-shrink-0">
                      <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-2">
                      <p class="text-md font-medium text-red-800">
                        {{ reservationInfo?.status_msg }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center text-center">
                  <button
                    type="submit"
                    class="w-2/4 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    class="w-2/4 ml-2 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                    @click="
                      router.push({
                        name: 'menu',
                        params: { menu_slug: restoStore.MENU_URL }
                      })
                    "
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </template>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
