<template>
  <div
    v-if="isLoading"
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-sm z-50"
  >
    <div class="w-16 h-16 border-t-4 border-b-4 border-violet-500 rounded-full animate-spin"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
