<script setup>
import { ref } from 'vue'
import { CreditCardIcon, BanknotesIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { updatPaymentTypeFn } from '@/services/api/restApiCalls'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const selectedPaymentType = ref(cartStore.CART_DATA?.order_payment_type)
const handleChangePayType = async () => {
  let payTypeFormData = new FormData()
  payTypeFormData.append('resto_id', restoStore?.RESTO_ID)
  payTypeFormData.append('payment_type', selectedPaymentType.value)
  const payment_type = await updatPaymentTypeFn(payTypeFormData)
  cartStore.CART_DATA = payment_type[restoStore?.RESTO_ID]
}
</script>
<template>
  <div class="bg-gray-200 flex items-center px-1 py-1 md:mb-2 mb-2 rounded-full w-full shadow">
    <button
      class="w-1/2 relative cursor-pointer text-base flex justify-center items-center sm:px-6 px-4 py-1 text-gray-800 rounded-full border-none focus:outline-none transition duration-200 ease-in-out disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-slate-400"
      :class="[
        cartStore.CART_DATA?.order_payment_type == 'cash' ? 'bg-yellow-600 text-white' : 'bg-white',
        !restoStore?.restoInfo?.is_cash_payment_available ? 'line-through' : ''
      ]"
      :disabled="!restoStore?.restoInfo?.is_cash_payment_available"
      @click="handleChangePayType((selectedPaymentType = 'cash'))"
    >
      <BanknotesIcon class="sm:h-5 h-4 sm:w-5 w-4 mr-1" aria-hidden="true" />
      <span class="sm:text-base text-sm">Cash</span>
      <XMarkIcon
        v-if="!restoStore?.restoInfo?.is_cash_payment_available"
        class="h-7 w-7 text-red-400 absolute"
        aria-hidden="true"
      />
    </button>
    <button
      class="w-1/2 relative cursor-pointer text-base flex justify-center items-center sm:px-3 px-2 py-1 text-gray-800 rounded-full border-none focus:outline-none transition duration-200 ease-in-out disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-slate-400"
      :class="[
        cartStore.CART_DATA?.order_payment_type == 'card' ? 'bg-purple-600 text-white' : 'bg-white',
        !restoStore?.restoInfo?.is_card_payment_available ? 'line-through' : ''
      ]"
      :disabled="!restoStore?.restoInfo?.is_card_payment_available"
      @click="handleChangePayType((selectedPaymentType = 'card'))"
    >
      <CreditCardIcon class="sm:h-5 h-4 sm:w-5 w-4 mr-1" aria-hidden="true" />
      <span class="sm:text-base text-sm"> Card</span>
      <XMarkIcon
        v-if="!restoStore?.restoInfo?.is_card_payment_available"
        class="h-7 w-7 text-red-400 absolute"
        aria-hidden="true"
      />
    </button>
  </div>
</template>
