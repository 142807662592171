<script setup>
import { ref } from 'vue'
import { CheckCircleIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { addVoucherCodeFn, removeVoucherCodeFn } from '@/services/api/restApiCalls'
const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const voucherCode = ref('')

const handleAddVoucherCode = async () => {
  const voucherCodeObj = {
    action: 'add_voucher',
    resto_id: restoStore?.RESTO_ID,
    add_voucher_code: voucherCode.value
  }
  var voucherCodeFormData = new FormData()
  voucherCodeFormData.append('cart_data', JSON.stringify(voucherCodeObj))
  const cart_info = await addVoucherCodeFn(voucherCodeFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  // this.cartStore.checkoutEnable = cart_info?.can_do_checkout
  voucherCode.value = ''
}
const handleRemoveVoucherCode = async () => {
  const voucherCodeObj = {
    action: 'remove_voucher',
    resto_id: restoStore?.RESTO_ID
  }
  var voucherCodeFormData = new FormData()
  voucherCodeFormData.append('cart_data', JSON.stringify(voucherCodeObj))
  const cart_info = await removeVoucherCodeFn(voucherCodeFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
}
</script>
<template>
  <div
    v-if="cartStore?.CART_DATA?.voucher_id"
    class="text-sm lg:text-md flex justify-between border border-gray-300 rounded-lg p-1 mb-2"
  >
    <div class="mr-2 flex items-center text-gray-900">
      <CheckCircleIcon class="h-8 w-8 text-green-400" aria-hidden="true" />
      <div class="ml-1">
        <span class="uppercase mr-1 font-semibold text-base lg:text-md">{{
          cartStore?.CART_DATA?.add_voucher_code
        }}</span
        ><span class="text-sm">voucher applied</span>
        <span
          v-if="cartStore?.CART_DATA?.voucher?.is_percent"
          class="text-xs block text-red-700 font-semibold"
          >£-{{ cartStore?.CART_DATA?.order_offer_discount }} ({{
            cartStore?.CART_DATA?.voucher?.discount_percent
          }}% off)</span
        >
        <span v-else class="text-xs block text-red-700 font-semibold"
          >£-{{ cartStore?.CART_DATA?.order_offer_discount }} off</span
        >
      </div>
    </div>
    <button
      type="submit"
      class="border border-transparent focus:outline-none focus:ring-2"
      @click="handleRemoveVoucherCode()"
    >
      <TrashIcon class="h-5 w-5 text-violet-700 mx-auto" aria-hidden="true" />
    </button>
  </div>
  <div v-else class="text-sm lg:text-md flex justify-between mb-2">
    <div class="mt-1 mr-2 w-2/3">
      <input
        id="voucherCode"
        v-model="voucherCode"
        name="voucherCode"
        type="text"
        autocomplete="voucherCode"
        placeholder="voucher code"
        class="block w-full appearance-none rounded-sm border border-gray-300 px-3 py-1 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        @keydown.enter.prevent="handleAddVoucherCode()"
      />
    </div>
    <button
      type="submit"
      class="h-8 rounded-sm border border-violet-700 bg-violet-700 text-white py-0 px-6 text-sm font-normal shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
      @click="handleAddVoucherCode()"
    >
      Add
    </button>
  </div>
</template>
