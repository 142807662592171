import { createRouter, createWebHistory } from 'vue-router'

import DynamicSiteView from '@/views/DynamicSiteView.vue'
import MobileAppView from '@/views/MobileAppView.vue'
import RestoHomeView from '@/views/RestoHomeView.vue'
import AboutView from '@/views/AboutView.vue'
import LoginView from '@/views/account/LoginView.vue'
import LogoutView from '@/views/account/LogoutView.vue'
import SignUpView from '@/views/account/SignUpView.vue'
import ForgotPasswordView from '@/views/account/ForgotPasswordView.vue'
import ResetPasswordView from '@/views/account/ResetPasswordView.vue'
import CustomerSettingView from '@/views/account/setting/CustomerSettingView.vue'
import UpdateEmailView from '@/views/account/setting/UpdateEmailView.vue'
import UpdatePasswordView from '@/views/account/setting/UpdatePasswordView.vue'
import MenuView from '@/views/menu/MenuView.vue'
import MobileCartView from '@/views/cart/MobileCartView.vue'
import OrderOptionsView from '@/views/order/OrderOptionsView.vue'
import CashOrderPaymentView from '@/views/payment/CashOrderPaymentView.vue'
import StripeSCAOrderPaymentView from '@/views/payment/StripeSCAOrderPaymentView.vue'
import OrderStatusView from '@/views/order/OrderStatusView.vue'
import MyOrdersView from '@/views/order/MyOrdersView.vue'
import ViewOrder from '@/views/order/ViewOrder.vue'
import ReservationView from '@/views/reservation/ReservationView.vue'
import ContactView from '@/views/contact/ContactView.vue'
import PosBillView from '@/views/posbill/PosBillView.vue'
import PosBillDoneView from '@/views/posbill/PosBillDoneView.vue'
import StopSms from '@/views/stopSms/StopSms.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import ErrorView from '@/views/ErrorView.vue'
import ClientLoginView from '@/views/client/LoginView.vue'
import ClientForgotPasswordView from '@/views/client/ForgotPasswordView.vue'
import ClientResetPasswordView from '@/views/client/ResetPasswordView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // route level code-splitting
  // this generates a separate chunk (About.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  routes: [
    {
      path: '/',
      name: 'dynamicSite',
      component: DynamicSiteView
    },
    {
      path: '/mobileappview',
      name: 'mobileAppView',
      component: MobileAppView
    },
    {
      path: '/restohome',
      name: 'restoHome',
      component: RestoHomeView
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView
    },
    {
      path: '/account/customer/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/account/customer/logout',
      name: 'logout',
      component: LogoutView
    },
    {
      path: '/account/customer/signup',
      name: 'signup',
      component: SignUpView
    },
    {
      path: '/account/customer/forgot_password',
      name: 'forgotPassword',
      component: ForgotPasswordView
    },
    {
      path: '/account/customer/reset/:token',
      name: 'resetPassword',
      component: ResetPasswordView
    },
    // Redirect old website reset password link to new vue website reset password link
    {
      path: '/customer/reset/:token',
      name: 'resetPasswordOld',
      redirect: (to) => {
        return { name: 'resetPassword', params: { token: to.params.token } }
      }
    },
    {
      path: '/account/customer/setting',
      name: 'setting',
      component: CustomerSettingView,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/customer/update_email',
      name: 'updateEmail',
      component: UpdateEmailView,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/customer/update_password',
      name: 'updatePassword',
      component: UpdatePasswordView,
      meta: { requiresAuth: true }
    },
    {
      path: '/resto/:menu_slug',
      name: 'menu',
      component: MenuView
    },
    {
      path: '/resto/view_mobile_cart',
      name: 'view_mobile_cart',
      component: MobileCartView
    },
    {
      path: '/resto/order_options',
      name: 'order_options',
      component: OrderOptionsView,
      meta: { requiresAuth: true }
    },
    {
      path: '/resto/cash_payment',
      name: 'cash_payment',
      component: CashOrderPaymentView,
      meta: { requiresAuth: true }
    },
    {
      path: '/resto/card_payment',
      name: 'stripe_card_payment',
      component: StripeSCAOrderPaymentView,
      meta: { requiresAuth: true }
    },
    {
      path: '/resto/order_status/:order_id',
      name: 'order_status',
      component: OrderStatusView,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/customer/orders',
      name: 'my_orders',
      component: MyOrdersView,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/customer/orders/:order_id',
      name: 'view_order',
      component: ViewOrder,
      meta: { requiresAuth: true }
    },
    {
      path: '/resto/:resto_id/reservation',
      name: 'reservation',
      component: ReservationView
    },
    {
      path: '/resto/:resto_id/contact',
      name: 'contact',
      component: ContactView
    },
    {
      path: '/resto/:resto_id/posbill/:table_id',
      name: 'posbill',
      component: PosBillView
    },
    {
      path: '/resto/:resto_id/posbilldone/:table_id',
      name: 'posbilldone',
      component: PosBillDoneView
    },
    {
      path: '/resto/:resto_id/stop_sms',
      name: 'stop_sms',
      component: StopSms
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFoundView
    },
    {
      path: '/error-page',
      name: 'error',
      component: ErrorView
    },
    {
      path: '/account/client/login',
      name: 'clientLogin',
      component: ClientLoginView
    },
    {
      path: '/account/client/forgot_password',
      name: 'clientForgotPassword',
      component: ClientForgotPasswordView
    },
    {
      path: '/account/client/reset/:token',
      name: 'clientResetPassword',
      component: ClientResetPasswordView
    },
    {
      path: '/client/reset/:token',
      name: 'clientResetPasswordOld',
      redirect: (to) => {
        return { name: 'resetPassword', params: { token: to.params.token } }
      }
    }
  ]
})

export default router
