<script>
import { ref, onBeforeMount, defineEmits } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { getRestoPaymentSettingFn, createOrderPIFn } from '@/services/api/restApiCalls'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    StripeElements,
    StripeElement,
    ChevronLeftIcon
  },

  setup() {
    const restoStore = useRestoGLobalStore()
    const cartStore = useCartGLobalStore()

    let stripeKey = ref('')
    let stripeAccountID = ref('')
    const stripeLoaded = ref(false)
    const cardErrorMsg = ref('')
    const card = ref()
    const elms = ref()
    const payButtonDisabled = ref(false)
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    })
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    })
    const style = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      },
      CardField: {
        padding: '5px',
        border: '1px solid gray'
      }
    }
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      style: style,
      hidePostalCode: true
    })

    const handleStripePayment = async () => {
      let cardElement = card.value.stripeElement
      elms.value.instance
        .createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        .then(async function (result) {
          // Handle result.error or result.paymentMethod
          if (result?.error) {
            cardErrorMsg.value = result?.error?.message
          }
        })
    }

    onBeforeMount(async () => {
      const create_pi_data = await createOrderPIFn(restoStore?.RESTO_ID)

      const payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
      if (payment_settings) {
        stripeKey.value = payment_settings?.public_key
        stripeAccountID.value = payment_settings?.account_id
        if (stripeKey.value) {
          let StripeConstructorOptions = {}
          if (stripeAccountID.value) {
            StripeConstructorOptions = { stripeAccount: stripeAccountID.value }
          }
          const stripePromise = loadStripe(stripeKey.value, StripeConstructorOptions)
          stripePromise.then(() => {
            stripeLoaded.value = true
          })
        }
      }
    })

    const handleCardChange = (event) => {
      payButtonDisabled.value = event.empty
      let error_msg = event.error ? event.error.message : ''
      cardErrorMsg.value = error_msg
      if (event.brand && event.brand === 'amex') {
        cardErrorMsg.value = 'Amex card is not supported'
        payButtonDisabled.value = true
      }
    }

    const emit = defineEmits(['handleBackToSplit'])

    return {
      restoStore,
      cartStore,
      stripeLoaded,
      stripeKey,
      stripeAccountID,
      cardErrorMsg,
      card,
      elms,
      instanceOptions,
      elementsOptions,
      cardOptions,
      payButtonDisabled,
      handleStripePayment,
      handleCardChange,
      emit
    }
  }
}
</script>
<template>
  <div class="flex items-baseline">
    <button
      type="button"
      class="mr-10 p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
      @click="$emit('handleBackToSplit')"
    >
      <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
    </button>
    <h3 class="text-2xl font-semibold text-gray-900 mb-5">Payment method</h3>
  </div>
  <div class="mb-4">
    <div class="py-5 px-3 border border-gray-200 shadow-md rounded-lg text-2xl mt-4">
      <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
      >
        <StripeElement
          ref="card"
          :elements="elements"
          :options="cardOptions"
          @change="handleCardChange($event)"
        ></StripeElement>
      </StripeElements>
    </div>
    <div class="text-sm font-medium text-red-600 lblError">{{ cardErrorMsg }}</div>
  </div>

  <div class="flex justify-between mx-1 text-gray-500 mb-1">
    <span class="text-lg font-medium mr-4">Subtotal (including tax)</span
    ><span class="text-lg font-medium">£16.85</span>
  </div>
  <div class="flex justify-between mx-1 mb-1">
    <span class="text-lg font-medium mr-4">Total</span
    ><span class="text-lg font-medium">£16.85</span>
  </div>
  <div class="text-right">
    <a href="#" class="underline text-gray-500">Have a promo code?</a>
  </div>
  <div class="text-center mt-4">
    <button
      class="w-full text-center bg-black text-white shadow-sm px-4 py-4 text-xl font-semibold rounded-full"
    >
      <span class="mr-auto"> Confirm</span>
    </button>
  </div>
</template>
