<script>
import { ref, reactive, computed } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs, minLength, helpers, maxLength } from '@vuelidate/validators'
import { registerNewUserFn, isCustomerEmailAvailableFn } from '@/services/api/restApiCalls'
import { XCircleIcon } from '@heroicons/vue/20/solid'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    XCircleIcon,
    LoadingSpinner
  },
  setup() {
    const authStore = useAuthGlobalStore()
    const restoGlobalStore = useRestoGLobalStore()
    const router = useRouter()
    const newUserInfo = ref({})
    const isLoading = ref(false)
    let userInitialValues = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: ''
    }
    let user = reactive({ ...userInitialValues })
    let registerError = ref(false)
    const checkIfEmailAvailable = async (value) => {
      // TOODO: add api call to check if email is available here
      let formData = new FormData()
      formData.append('email', value)
      const response = await isCustomerEmailAvailableFn(formData)
      const isEmailAvailable = response.is_email_available || false
      return isEmailAvailable
    }

    const validationRules = computed(() => {
      let rules = {
        firstName: {
          required
        },
        lastName: { required },
        email: {
          required,
          email,
          $lazy: true,
          isUnique: helpers.withAsync(
            helpers.withMessage('Email not available', checkIfEmailAvailable)
          )
        },
        password: {
          required,
          minLength: minLength(5)
        },
        confirmPassword: { required, sameAs: sameAs(user.password) },
        phoneNumber: { minLength: minLength(10), maxLength: maxLength(15) }
      }
      return rules
    })

    const v$ = useVuelidate(validationRules, user)
    const submitted = ref(false)

    const handleRegistrationError = () => {
      Object.assign(user, userInitialValues)
      registerError.value = true
      v$.value.$reset()
    }

    const handleRegisterUser = async () => {
      // if (submitted.value) return
      submitted.value = true
      registerError.value = false
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      const menuSlug = restoGlobalStore.restoInfo.menu_url
      const currentDate = new Date().toISOString().split('T')[0] //'YYYY-MM-DD' format
      const signupCaptcha = `beluga-signup-${currentDate}`
      const base64EncodedValue = btoa(signupCaptcha) //Encoded signupCaptcha
      let registerFormData = new FormData()
      registerFormData.append('first_name', user.firstName)
      registerFormData.append('last_name', user.lastName)
      registerFormData.append('email', user.email)
      registerFormData.append('password', user.password)
      registerFormData.append('phone_number', user.phoneNumber)
      registerFormData.append('beluga_captcha', base64EncodedValue)

      const new_user_data = await registerNewUserFn(registerFormData)
      newUserInfo.value = new_user_data
      if (newUserInfo.value.status == 400) {
        registerError.value = true
        isLoading.value = false
        notify(
          {
            group: 'errormessage',
            title: 'Error',
            text: `${newUserInfo.value.status_msg}`
          },
          2000
        )
        return
      }
      if (new_user_data.email === user.email) {
        authStore.login(user.email, user.password).then((isLoggedIn) => {
          if (isLoggedIn) {
            router.push({ name: 'menu', params: { menu_slug: menuSlug } })
          } else {
            // handleRegistrationError()
            registerError.value = true
          }
        })
      } else {
        // handleRegistrationError()
        registerError.value = true
      }
      isLoading.value = false
      submitted.value = false
    }
    return {
      router,
      authStore,
      registerError,
      submitted,
      user,
      v$,
      handleRegisterUser,
      newUserInfo,
      isLoading
    }
  }
}
</script>

<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0 dark:bg-gray-900"
    >
      <div class="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="text-center text-2xl font-bold tracking-tight text-gray-900">
            Sign up to Proceed
          </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-4xl">
          <form @submit.prevent="handleRegisterUser()">
            <div class="space-y-4">
              <div v-if="registerError" class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-md font-medium text-red-800">
                      {{ newUserInfo?.status_msg }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-6">
                  <label for="firstName" class="block text-sm font-medium text-gray-700"
                    >First Name</label
                  >
                  <div class="mt-1">
                    <input
                      id="firstName"
                      v-model="user.firstName"
                      name="firstName"
                      type="text"
                      autocomplete="firstName"
                      :class="{ 'border-red-600': submitted && v$.firstName.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.firstName.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="md:col-span-6 mt-0">
                  <label for="lastName" class="block text-sm font-medium text-gray-700"
                    >Last Name</label
                  >
                  <div class="mt-1">
                    <input
                      id="lastName"
                      v-model="user.lastName"
                      name="lastName"
                      type="text"
                      autocomplete="lastName"
                      :class="{ 'border-red-600': submitted && v$.lastName.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.lastName.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="md:col-span-6">
                <label for="email" class="block text-sm font-medium text-gray-700"
                  >Email address</label
                >
                <div class="mt-1">
                  <input
                    @blur="v$.email.$touch"
                    id="email"
                    v-model="user.email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    :class="{ 'border-red-600': submitted && v$.email.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div v-for="(error, index) of v$.email.$errors" :key="index" class="input-errors">
                  <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                </div>
              </div>

              <div class="md:col-span-6">
                <label for="password" class="block text-sm font-medium text-gray-700"
                  >Password</label
                >
                <div class="mt-1">
                  <input
                    id="password"
                    v-model="user.password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    :class="{ 'border-red-600': submitted && v$.password.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.password.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                </div>
              </div>

              <div class="md:col-span-6">
                <label for="confirmPassword" class="block text-sm font-medium text-gray-700"
                  >Confirm Password</label
                >
                <div class="mt-1">
                  <input
                    id="confirmPassword"
                    v-model="user.confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autocomplete="current-password"
                    :class="{ 'border-red-600': submitted && v$.confirmPassword.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.confirmPassword.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                </div>
              </div>

              <div class="md:col-span-6">
                <label for="phoneNumber" class="block text-sm font-medium text-gray-700"
                  >Phone Number</label
                >
                <div class="mt-1">
                  <input
                    id="phoneNumber"
                    v-model="user.phoneNumber"
                    name="phoneNumber"
                    type="text"
                    autocomplete="phoneNumber"
                    :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.phoneNumber.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                </div>
              </div>

              <div class="md:col-span-6">
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              </div>

              <div class="md:col-span-6">
                <button
                  type="button"
                  class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                  @click="$router.push({ name: 'login' })"
                >
                  Back to Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
