<script setup></script>
<template>
  <div class="item-wrapper">
    <div class="flex justify-between mb-3">
      <div class="flex items-center md:w-3/5 mx-1">
        <span class="text-lg font-medium mr-4">1</span>
        <p class="text-lg font-medium">Aloo Pakora</p>
      </div>
      <div class="mx-1">
        <span class="text-lg font-medium">£2.85</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <div class="flex items-center md:w-3/5 mx-1">
        <span class="text-lg font-medium mr-4">1</span>
        <p class="text-lg font-medium">Doner Kabab - Small</p>
      </div>
      <div class="mx-1">
        <span class="text-lg font-medium">£7.60</span>
      </div>
      <div class="w-full ml-8">+No salad, Ketchup, Dr Pepper,</div>
      <div class="w-full ml-8">+2xCheese slice - £0.40,</div>
    </div>
  </div>
  <hr class="w-full my-3" />
  <div class="flex justify-between mx-1">
    <span class="text-lg font-medium mr-4">Service charge</span>
    <span class="text-lg font-medium">£2.85</span>
  </div>
</template>
