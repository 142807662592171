<script setup>
import { useCartGLobalStore } from '@/stores/cartGlobalStore'

const cartStore = useCartGLobalStore()
</script>
<template>
  <div class="lg:col-span-6 bg-white border border-gray-200 rounded-sm sm:p-6 p-4">
    <div class="border-b border-gray-200 pb-5">
      <h3 class="text-xl font-semibold leading-6 text-gray-900">Your Order</h3>
    </div>
    <!-- <template v-if="cartStore.CART_DATA?.items">
      <div v-for="item in Object.keys(cartStore.CART_DATA?.items)" :key="item">
        <div class="flex items-center justify-between pt-2">
          <dt class="text-gray-900 md:font-semibold font-normal md:text-base text-sm">
            {{
              cartStore.CART_DATA?.items[item]?.quantity +
              ' x ' +
              cartStore.CART_DATA?.items[item]?.item?.item_name
            }}
          </dt>
          <dd class="text-gray-900 md:font-semibold font-normal md:text-base text-sm">
            £{{ cartStore.CART_DATA?.items[item]?.total_price }}
          </dd>
        </div>
      </div>
    </template>
    <template v-if="cartStore.CART_DATA?.c_items">
      <div v-for="item in Object.keys(cartStore.CART_DATA?.c_items)" :key="item">
        <div class="flex items-center justify-between pt-2">
          <dt class="text-gray-900 font-semibold md:text-base text-sm">
            {{
              cartStore.CART_DATA?.c_items[item]?.quantity +
              ' x ' +
              cartStore.CART_DATA?.c_items[item]?.item?.item_name
            }}
          </dt>
          <dd class="text-gray-900 font-semibold md:text-base text-sm">
            £{{ cartStore.CART_DATA?.c_items[item]?.total_price }}
          </dd>
        </div>
        <div
          v-if="cartStore.CART_DATA?.c_items[item]?.accesory_extras.length"
          class="w-full ml-4 text-gray-900 font-normal text-sm"
        >
          +<template
            v-for="acc_extra in cartStore.CART_DATA?.c_items[item]?.accesory_extras"
            :key="acc_extra.extra_id"
            >{{ acc_extra.extra_name + ', ' }}</template
          >
        </div>
        <div
          v-if="cartStore.CART_DATA?.c_items[item]?.optional_extras.length"
          class="w-full ml-4 text-gray-900 font-normal text-sm"
        >
          +<template
            v-for="opt_extra in cartStore.CART_DATA?.c_items[item]?.optional_extras"
            :key="opt_extra.extra_id"
            >{{
              opt_extra.quantity + 'x' + opt_extra.extra.extra_name + ' - £' + opt_extra.total_price
            }}</template
          >
        </div>
      </div>
    </template> 
    <template v-if="cartStore.CART_DATA?.m_items">
      <div v-for="item in Object.keys(cartStore.CART_DATA?.m_items)" :key="item">
        <div class="flex items-center justify-between pt-2">
          <dt class="text-gray-900 font-semibold text-md">
            {{
              cartStore.CART_DATA?.m_items[item]?.quantity +
              ' x ' +
              cartStore.CART_DATA?.m_items[item]?.item?.item_name
            }}
          </dt>
          <dd class="text-gray-900 font-medium text-md">
            £{{ cartStore.CART_DATA?.m_items[item]?.total_price }}
          </dd>
        </div>
        <div
          v-for="mealPart in cartStore.CART_DATA?.m_items[item]?.meal_parts"
          :key="mealPart.meal_item.item_id"
          class="w-full mb-1 ml-4 text-gray-900 font-normal text-sm"
        >
          <p class="text-gray-900 font-medium text-sm">{{ mealPart?.meal_item.item_name }}</p>
          <div v-if="mealPart?.accesory_extras" class="w-full">
            +<template v-for="acc_extra in mealPart?.accesory_extras" :key="acc_extra.extra_id">{{
              acc_extra.extra_name + ', '
            }}</template>
          </div>
          <div v-if="mealPart?.optional_extras.length" class="w-full">
            +<template v-for="opt_extra in mealPart?.optional_extras" :key="opt_extra.extra_id">{{
              opt_extra.quantity + 'x' + opt_extra.extra.extra_name + ' - £' + opt_extra.total_price
            }}</template>
          </div>
        </div>
      </div>
    </template>-->

    <template v-if="cartStore?.CART_DATA?.all_items">
      <div v-for="item in cartStore?.CART_DATA?.all_items" :key="item">
        <div class="flex items-center justify-between pt-2">
          <dt class="text-gray-900 md:font-semibold font-normal md:text-base text-sm">
            {{ item?.quantity + ' x ' + item?.item?.item_name }}
          </dt>
          <dd class="text-gray-900 md:font-semibold font-normal md:text-base text-sm">
            £{{ item?.total_price }}
          </dd>
        </div>
        <div
          v-if="item?.accesory_extras?.length"
          class="w-full ml-4 text-gray-900 font-normal text-sm"
        >
          +<template v-for="(acc_extra, index) in item?.accesory_extras" :key="acc_extra.extra_id"
            >{{ acc_extra.extra_name }}
            <span v-if="acc_extra?.price > 0"> - £{{ acc_extra?.price }}</span>
            <span v-if="index < item?.accesory_extras.length - 1">,&nbsp;</span>
          </template>
        </div>
        <div
          v-if="item?.optional_extras?.length"
          class="w-full ml-4 text-gray-900 font-normal text-sm"
        >
          <div v-for="(opt_extra, index) in item?.optional_extras" :key="opt_extra.extra_id">
            +{{
              opt_extra.quantity + 'x' + opt_extra.extra.extra_name + ' - £' + opt_extra.total_price
            }}
          </div>
        </div>
        <div
          v-for="mealPart in item?.meal_parts"
          :key="mealPart.meal_item.item_id"
          class="w-full mb-1 ml-4 text-gray-900 font-normal text-sm"
        >
          <p class="text-gray-900 font-medium text-sm">{{ mealPart?.meal_item.item_name }}</p>
          <div v-if="mealPart?.accesory_extras?.length" class="w-full">
            +<template
              v-for="(acc_extra, index) in mealPart?.accesory_extras"
              :key="acc_extra.extra_id"
              >{{ acc_extra.extra_name }}
              <span v-if="index < mealPart?.accesory_extras.length - 1">,&nbsp;</span>
            </template>
          </div>
          <div v-if="mealPart?.optional_extras?.length" class="w-full">
            <div v-for="(opt_extra, index) in mealPart?.optional_extras" :key="opt_extra.extra_id">
              +{{
                opt_extra.quantity +
                'x' +
                opt_extra.extra.extra_name +
                ' - £' +
                opt_extra.total_price
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <hr class="mt-3" />
    <div class="divide-y divide-gray-200">
      <div class="flex items-center justify-between py-2">
        <dt class="text-gray-900 font-semibold md:text-base text-sm">Subtotal</dt>
        <dd class="font-medium text-gray-900 md:text-base text-sm">
          £{{ cartStore.CART_DATA?.order_subtotal || 0 }}
        </dd>
      </div>
      <div class="flex items-center justify-between py-2">
        <dt class="text-red-500 font-semibold md:text-base text-sm">Discount</dt>
        <dd class="font-medium text-red-500 md:text-base text-sm">
          £-{{ cartStore.CART_DATA?.order_offer_discount || 0 }}
        </dd>
      </div>
      <div
        v-if="cartStore.CART_DATA?.order_type == 'delivery'"
        class="flex items-center justify-between py-2"
      >
        <dt class="text-gray-900 font-semibold md:text-base text-sm">Delivery Fee</dt>
        <dd class="font-medium text-gray-900 md:text-base text-sm">
          £{{ cartStore.CART_DATA?.delivery_charge }}
        </dd>
      </div>
      <div class="flex items-center justify-between py-2">
        <dt class="text-gray-900 font-semibold md:text-base text-sm">Service Charge</dt>
        <dd class="font-medium text-gray-900 md:text-base text-sm">
          £{{ cartStore.CART_DATA?.service_charge_fee }}
        </dd>
      </div>
      <div class="flex items-center justify-between py-2">
        <dt class="text-gray-900 font-semibold md:text-base text-sm">Total</dt>
        <dd class="font-medium text-gray-900 md:text-base text-sm">
          £{{ cartStore.CART_DATA?.order_total }}
        </dd>
      </div>
    </div>
  </div>
</template>
