<script setup>
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
const cartStore = useCartGLobalStore()
</script>
<template>
  <div
    v-if="cartStore?.RESTO_STATUS?.is_open && cartStore?.RESTO_STATUS?.resto_is_offline != null"
    class="p-2 mb-1 text-center text-lg font-semibold bg-red-500 text-white"
  >
    Sorry!! {{ cartStore?.RESTO_STATUS?.resto_is_offline_message }}
  </div>
</template>
