<script setup>
import { ref, computed } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['handleBackToSplit'])

const totalPerson = ref(5)
const payForPerson = ref(1)
const handlePayForPerson = () => {
  if (payForPerson.value > 1) {
    payForPerson.value -= 1
  }
}
const handleOutOfPerson = () => {
  if (totalPerson.value > 1) {
    totalPerson.value -= 1
  }
}

// const completedPercentage = computed(() => {
//   return (pendingValue.value / totalValue.value) * 100
// })

// const pendingPercentage = computed(() => {
//   return 100 - completedPercentage.value
// })
// console.log('pendingPercentage', pendingPercentage.value)

// const rotateValue = computed(() => {
//   return (pendingPercentage.value / 100) * 360
// })

const percentage = computed(() => (payForPerson.value / totalPerson.value) * 100)
const rotation = computed(() => (percentage.value / 100) * 284)
</script>
<template>
  <!-- <div class="relative w-24 h-24">
    <div class="absolute inset-0 border border-solid border-gray-300 rounded-full"></div>
    <div
      class="absolute inset-0 clip-circle"
      :style="{
        background: `conic-gradient(#3498db ${completedPercentage}%, #ecf0f1 ${completedPercentage}% 100%)`
      }"
    ></div>
    <div class="absolute inset-0 flex items-center justify-center text-white font-bold">
      {{ pendingValue }} / {{ totalValue }}
    </div>
  </div> -->
  <div class="mb-3 mx-1">
    <div class="flex items-baseline">
      <button
        type="button"
        class="mr-10 p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
        @click="$emit('handleBackToSplit')"
      >
        <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
      </button>
      <h3 class="text-2xl font-semibold text-gray-900 mb-5">Divide the bill equally</h3>
    </div>
    <div class="flex items-center justify-center mb-6">
      <svg class="w-48 h-48" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle
          class="stroke-current text-gray-300"
          cx="50"
          cy="50"
          r="45"
          stroke-width="6"
          fill="transparent"
        />
        <circle
          :style="{ strokeDasharray: `${rotation}, 280` }"
          class="transition-all duration-500 ease-in-out stroke-current text-blue-500"
          cx="50"
          cy="50"
          r="45"
          stroke-width="6"
          fill="transparent"
        />
        <text class="text-white font-bold" x="50%" y="50%" text-anchor="middle">
          {{ payForPerson }} / {{ totalPerson }}
        </text>
      </svg>
    </div>
    <div class="flex justify-center items-center mb-6">
      <div class="text-xl text-gray-800 w-24">Paying for</div>
      <div class="flex justify-between items-center bg-black text-white rounded-full mx-3">
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring-2 disabled:text-gray-400"
          @click="handlePayForPerson()"
        >
          <MinusCircleIcon class="h-10 w-10 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-lg mx-6">{{ payForPerson }}</span>
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring-2 disabled:text-gray-400"
          @click="payForPerson += 1"
        >
          <PlusCircleIcon class="h-10 w-10 mx-auto" aria-hidden="true" />
        </button>
      </div>
      <div class="text-xl text-gray-800 w-24">person</div>
    </div>
    <div class="flex justify-center items-center">
      <div class="text-xl text-gray-800 w-24">Out of</div>
      <div class="flex justify-between items-center bg-black text-white rounded-full mx-3">
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring-2 disabled:text-gray-400"
          @click="handleOutOfPerson()"
        >
          <MinusCircleIcon class="h-10 w-10 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-lg mx-6">{{ totalPerson }}</span>
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring-2 disabled:text-gray-400"
          @click="totalPerson += 1"
        >
          <PlusCircleIcon class="h-10 w-10 mx-auto" aria-hidden="true" />
        </button>
      </div>
      <div class="text-xl text-gray-800 w-24">at the table</div>
    </div>
    <hr class="w-full my-3" />
    <div class="flex justify-between items-center text-2xl font-semibold">
      <span>You're paying</span>
      <span>£16.08</span>
    </div>
    <!-- <div class="text-center mt-4">
      <button
        class="w-full text-center bg-black text-white shadow-sm px-4 py-4 text-xl font-semibold rounded-full mt-4"
      >
        <span class="mr-auto"> Confirm</span>
      </button>
    </div> -->
  </div>
</template>

<style scoped>
.clip-half-circle {
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
}
.clip-circle {
  clip-path: circle(50% at 50% 50%);
}
</style>
