<script>
import { ref } from 'vue'
import { UserCircleIcon } from '@heroicons/vue/20/solid'
import { BookOpenIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'
// import { customerReservationFn, getCustomerInfoFn } from '@/services/api/restApiCalls'
import ItemsList from '@/components/posbill/ItemsList.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'

export default {
  components: {
    BookOpenIcon,
    ChevronDownIcon,
    UserCircleIcon,
    ItemsList
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const showPayBillModal = ref(false)
    const showPayment = ref(false)
    return {
      name,
      router,
      restoStore,
      showPayBillModal,
      showPayment
    }
  }
}
</script>
<template>
  <!-- <AppLayout> -->
  <div class="flex flex-col items-center justify-center mx-auto bg-gray-100">
    <div class="w-full max-w-2xl bg-white rounded-sm shadow">
      <div class="top-banner">
        <div class="overflow-hidden -mt-8">
          <img
            class="block h-20 w-auto mx-auto rounded-full"
            :src="restoStore.restoInfo.logo"
            :alt="restoStore.restoInfo.name"
          />
        </div>
      </div>
      <div class="mt-10 px-4 py-6">
        <div class="text-center mb-4">
          <span class="text-sm">Table 40 </span>
          <button
            class="w-full flex items-center justify-center bg-indigo-100 px-4 py-4 text-xl font-semibold rounded-3xl mt-4"
          >
            <BookOpenIcon class="h-6 w-6 mr-auto" aria-hidden="true" />
            <span class="mr-auto"> View Menu</span>
          </button>
        </div>
        <div class="px-4 py-4 shadow-sm border border-gray-100 rounded-xl">
          <div class="flex justify-between items-center">
            <span class="text-2xl font-semibold">Pay your bill</span>
            <span class="text-2xl font-semibold">£32.08</span>
          </div>
          <hr class="w-full my-4" />
          <div class="flex justify-between items-center">
            <span class="text-2xl font-semibold">Left to pay</span>
            <span class="text-2xl font-semibold">£0.00</span>
          </div>
          <div class="">
            <button
              @click="showPayment = !showPayment"
              class="w-full text-left text-gray-400 px-3 py-2 text-lg"
            >
              <span v-if="showPayment" class="mr-auto inline">Hide payment</span>
              <span v-else class="mr-auto inline">Show payment</span>
              <ChevronDownIcon
                :class="showPayment ? 'rotate-180 transform' : ''"
                class="h-6 w-6 inline ml-2"
                aria-hidden="true"
              />
            </button>
            <div :class="[showPayment ? 'showPayment' : 'hidePayment']" class="pt-2">
              <div class="flex justify-between items-center text-gray-500">
                <span class="text-lg"
                  ><UserCircleIcon class="h-6 w-6 inline mb-1" aria-hidden="true" /> Someone paid on
                  till</span
                >
                <span class="text-lg">£19.00</span>
              </div>
              <div class="flex justify-between items-center text-gray-500">
                <span class="text-lg"
                  ><UserCircleIcon class="h-6 w-6 inline mb-1" aria-hidden="true" /> Someone paid on
                  till</span
                >
                <span class="text-lg">£19.00</span>
              </div>
            </div>
          </div>
          <hr class="w-full my-3" />
          <ItemsList></ItemsList>
        </div>
      </div>
    </div>
  </div>
  <!-- </AppLayout> -->
</template>
<style scoped>
.top-banner {
  width: 100%;
  height: 0px;
  padding-top: 40%;
  border-radius: 0px 0px 24px 24px;
  background-image: url('http://static.apricot-it.com/dyn/cdn/10500/img/slider/background_image_1.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.showPayment {
  display: block;
  overflow: hidden;
  transition: height 0.2s ease-in-out 0s;
  height: 68px;
}
.hidePayment {
  display: block;
  overflow: hidden;
  transition: height 0.2s ease-in-out 0s;
  height: 0;
}
</style>
