<script>
import { ref, watch } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import PostCodeModal from './PostCodeModal.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { canRestoServePostCodeFn, getCartInfoFn } from '@/services/api/restApiCalls'
export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    PencilSquareIcon
    // PostCodeModal
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const cartStore = useCartGLobalStore()
    const showPostCodeModal = ref(false)

    const setPostCode = ref('')
    const postCodeData = ref({})
    const invalidPostCode = ref(false)
    const notDelivery = ref(false)
    const submitted = ref(false)
    const showLabel = ref(true)
    const validPostCode = ref(false)

    const handleChangePostcode = () => {
      // showPostCodeModal.value = true
      cartStore.SHOW_POSTCODE_MODAL = true
    }
    const handleClosePostCodeModal = () => {
      v$.value.$reset()
      setPostCode.value = ''
      // showPostCodeModal.value = false
      cartStore.SHOW_POSTCODE_MODAL = false
      notDelivery.value = false
      showLabel.value = true
      invalidPostCode.value = false
      validPostCode.value = false
    }
    const validationRules = {
      setPostCode: {
        required
      }
    }
    const v$ = useVuelidate(validationRules, {
      setPostCode
    })

    const handleCheckPostCode = async () => {
      submitted.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      const can_resto_serve = await canRestoServePostCodeFn(setPostCode.value, restoStore?.RESTO_ID)
      postCodeData.value = can_resto_serve
      if (can_resto_serve?.can_resto_serve) {
        notDelivery.value = false
        validPostCode.value = true
        handleClosePostCodeModal()
        const cart_info = await getCartInfoFn(restoStore?.RESTO_ID)
        cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
      } else {
        if (can_resto_serve?.error_code == 'can_not_deliver') {
          notDelivery.value = true
          showLabel.value = false
          invalidPostCode.value = false
        } else {
          invalidPostCode.value = true
          notDelivery.value = false
          showLabel.value = true
        }
      }
    }
    watch(setPostCode, () => {
      invalidPostCode.value = false
      notDelivery.value = false
      showLabel.value = true
    })
    return {
      restoStore,
      cartStore,
      showPostCodeModal,
      setPostCode,
      postCodeData,
      invalidPostCode,
      notDelivery,
      submitted,
      showLabel,
      validPostCode,
      v$,
      handleChangePostcode,
      handleClosePostCodeModal,
      handleCheckPostCode
    }
  }
}
</script>

<template>
  <template v-if="cartStore?.CART_DATA?.order_type == 'delivery'">
    <div class="w-full flex justify-between border border-gray-300 rounded-lg p-2 mb-2">
      <div class="text-green-600 mr-2 flex items-center">
        <svg
          class="h-7 w-7 fill-current mr-2 my-auto"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path
            d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
          ></path>
        </svg>
        <div v-if="cartStore?.CART_DATA?.pc_data?.post_code" class="text-gray-900">
          <span class="uppercase mr-1 font-semibold text-base lg:text-md">{{
            cartStore?.CART_DATA?.pc_data?.post_code
          }}</span
          ><span class="text-sm">
            ( Distance
            <span class="font-medium">{{ cartStore?.CART_DATA?.pc_data?.distance_mi }} miles </span
            >)
          </span>
          <!-- <span class="text-xs block"
            >( Distance
            <span class="font-medium">{{ cartStore?.CART_DATA?.pc_data?.distance_mi }} miles </span
            >)</span
          > -->
        </div>
        <div v-else>
          <span class="text-sm text-gray-900">Enter Delivery Postcode</span>
        </div>
      </div>
      <button
        type="submit"
        class="border border-transparent text-violet-700 focus:outline-none focus:ring-2"
        @click="handleChangePostcode()"
      >
        <PencilSquareIcon class="h-5 w-5 text-violet-700 mx-auto" aria-hidden="true" />
      </button>
    </div>
    <!-- <div v-else class="text-sm lg:text-md flex justify-between mb-2">
      <div class="mt-1 mr-2 w-2/3">
        <input
          id="postCode"
          v-model="setPostCode"
          name="postCode"
          type="text"
          autocomplete="postCode"
          placeholder="Enter Postcode"
          :class="{ 'border-red-600': submitted && v$.setPostCode.$error }"
          class="block w-full appearance-none rounded-sm border border-gray-300 px-3 py-1 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
        <div
          v-for="(error, index) of v$.setPostCode.$errors"
          :key="index"
          class="input-errors text-left"
        >
          <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
        </div>
        <div v-if="invalidPostCode" class="text-sm font-medium text-red-600 text-left mt-1">
          Invalid Post Code
        </div>
        <div v-if="notDelivery" class="text-sm font-medium text-red-600 text-left mt-1">
          We don't deliver to: {{ postCodeData?.post_code }}
        </div>
      </div>
      <button
        type="submit"
        class="h-8 rounded-sm border border-violet-700 bg-violet-700 text-white py-0 px-6 text-sm font-normal shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
        @click="handleCheckPostCode()"
      >
        Add
      </button>
    </div> -->
  </template>
  <TransitionRoot as="template" :show="cartStore?.SHOW_POSTCODE_MODAL">
    <Dialog as="div" class="relative z-40" @close="handleClosePostCodeModal()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-20 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg bg-white px-5 pt-6 pb-5 text-left shadow-xl transition-all my-8 sm:w-full sm:max-w-md sm:p-6"
            >
              <div class="absolute top-0 right-0 pt-2 pr-2 block">
                <button
                  type="button"
                  class="rounded-full border border-e-violet p-1 bg-white text-e-violet hover:text-e-violet focus:outline-none"
                  @click.stop="handleClosePostCodeModal()"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div class="overflow-auto">
                <h3 class="text-xl font-semibold text-gray-900 mb-3 text-center block">
                  Confirm Postcode
                </h3>
                <form
                  v-if="!validPostCode"
                  class="text-center"
                  @submit.prevent="handleCheckPostCode"
                >
                  <div class="mb-3">
                    <div v-if="notDelivery">
                      <h4 class="block text-lg font-medium text-gray-700">
                        We don't deliver to:
                        <span class="block text-lg font-medium text-red-500 uppercase">{{
                          postCodeData?.post_code
                        }}</span>
                      </h4>
                      <div class="">
                        <label
                          v-if="postCodeData?.pc_data?.valid_delivery_postcodes.length"
                          class="block text-md text-gray-900 uppercase whitespace-normal"
                        >
                          Postcode must start with:
                          <span
                            v-for="(postCode, index) in postCodeData?.pc_data
                              ?.valid_delivery_postcodes"
                            :key="index"
                            class="font-medium text-red-500 whitespace-normal"
                            >{{ postCode
                            }}<span
                              v-if="
                                index < postCodeData?.pc_data?.valid_delivery_postcodes.length - 1
                              "
                              >,
                            </span></span
                          >
                        </label>
                        <label class="block text-md text-gray-900">
                          Max delivery Radius:
                          <span class="font-medium"
                            >{{ postCodeData?.pc_data?.delivery_radius_mi }} miles</span
                          >
                        </label>
                        <label class="block text-md text-gray-900 mb-2">
                          Postcode distance:
                          <span class="font-medium"
                            >{{ postCodeData?.pc_data?.distance_mi }} miles</span
                          >
                        </label>
                      </div>
                      <label class="block text-md font-medium text-gray-900 mb-2">
                        Please, enter new post code or try collection
                      </label>
                    </div>
                    <label
                      v-if="showLabel"
                      for="postCode"
                      class="block text-md font-medium text-gray-900"
                      >So we can check if we serve your area</label
                    >
                    <div class="mt-1">
                      <input
                        id="postCode"
                        v-model="setPostCode"
                        name="postCode"
                        type="text"
                        autocomplete="postCode"
                        placeholder="Enter Postcode"
                        :class="{ 'border-red-600': submitted && v$.setPostCode.$error }"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.setPostCode.$errors"
                      :key="index"
                      class="input-errors text-left"
                    >
                      <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                    </div>
                    <div
                      v-if="invalidPostCode"
                      class="text-sm font-medium text-red-600 text-left mt-1"
                    >
                      Invalid Post Code
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="w-full rounded-md border border-transparent bg-violet-700 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
                    :disabled="invalidPostCode || notDelivery"
                  >
                    Confirm
                  </button>
                </form>
                <!-- <div v-if="validPostCode" class="text-center">
                  <h4 class="block text-xl font-medium text-green-800 mb-3">
                    Awesome!! <br />
                    You can continue ordering
                  </h4>
                  <button
                    type="button"
                    class="w-full rounded-md border border-violet-700 bg-violet-700 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50"
                    @click.stop="handleClosePostCodeModal()"
                  >
                    Continue
                  </button>
                </div> -->
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- <PostCodeModal v-if="showPostCodeModal" :showPostCodeModal="showPostCodeModal"></PostCodeModal> -->
</template>
